import AppSoftphone from "./app-softphone";

/**
 * This is a utility class that manages the lifecycle of the softphone component.
 * 
 * It lives on the main app component and survives navigation, preventing disconnects from changing screens in the app.
 * 
 * When a call is completed, the softphone component is destroyed. This is because the SIP authentication is what drives
 * the outbound caller id number. This changes per patient and client, so for different patients a new authentication
 * session needs to be established.
 * 
 * Its current state is a basic "quick and dirty" way to try to address multiple issue surrounding calls being dropped.
 * If successfull, this component and the app-softphone component should be re-evaluated and potentially combined.
 * 
 * This component should be a singleton, there should only ever be one instance and it should be attached to the main
 * application component.
 */
class AppSoftphoneContainer extends HTMLElement {
    connectedCallback() {
        this.innerHTML = /*html*/`
        <style>
            #softphone-container {
                position: fixed;
                /*height: fit-content;*/
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -100px;
                transition: all .250s ease-in-out;
            }

            #softphone-container.visible {
                top: 5px;
                z-index: 1600;
            }

        </style>
        <div id="softphone-container"></div>
        `;
    }

    async dial(patient_id, task, phone_number, callback) {
        console.log('AppSoftphoneContainer.dial called with:', { patient_id, task, phone_number });

        let container_element = this.querySelector("#softphone-container");
        let existing_instances = container_element.querySelectorAll("app-softphone");
        for (let instance of existing_instances) {
            await instance.destroy();
        }
        container_element.innerHTML = "";

        let app_softphone = new AppSoftphone();
        app_softphone.style.pointerEvents = "all";
        app_softphone.addEventListener("hide", async () => {
            await app_softphone.destroy();
            container_element.classList.remove("visible");
        });
        app_softphone.addEventListener("hangup", async () => {
            await app_softphone.destroy();
            container_element.classList.remove("visible");
            if (callback)
                await callback();
        });
        //clean up after it's hidden and destroy the element
        app_softphone.addEventListener("animationend", () => {
            if (!app_softphone.classList.contains("visible")) {
                app_softphone.onanimationend = null;
                app_softphone.destroy();
                container_element.innerHTML = "";
            }
        });
        container_element.appendChild(app_softphone);
        app_softphone.patient_id = patient_id;
        app_softphone.selected_phone_number = phone_number;
        app_softphone.task = task;
        container_element.classList.add("visible");
        await app_softphone.dial();
    }
}

customElements.define('app-softphone-container', AppSoftphoneContainer);
export default AppSoftphoneContainer;
