import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import { format } from "date-fns";
import directus from "../../lib/lib-directus";
import Cache from "../../lib/lib-cache";
import { getCurrentClientId } from "../../lib/lib-user";
import { parseISODateWithoutTime } from "../../lib/lib-date";
import AppPatientCareTeamModal from "./app-patient-care-team-modal";
import AppPatientContactInfo from "./app-patient-contact-info";
import { ref, createRef } from 'lit/directives/ref.js';

export default class AppPatientProfile extends HTMLElement {
    set patient(value) {
        this._patient = value;
        this.expandPatient();
        this.render();
    }
    get patient() {
        return this._patient;
    }
    get has_changes() {
        return this._has_changes;
    }

    set has_changes(value) {
        this._has_changes = !!value;
        this.render();
    }

    get editable() {
        return this._editable;
    }

    set editable(value) {
        this._editable = !!value;
        this.render();
    }

    get clinicians() {
        return this._clinicians;
    }

    set clinicians(values) {
        this._clinicians = values;
    }

    get case_managers() {
        return this._case_managers;
    }

    set case_managers(values) {
        this._case_managers = values;
    }

    set care_team(values) {
        this._care_team = values;
        this.render();
    }

    get care_team() {
        return this._care_team;
    }

    constructor() {
        super();
        this._has_changes = false;
        this._editable = false;
        this._clinicians = null;
        this._care_team = null;
        this.changes = {};
        this.patient_access_changes = {};
    }

    connectedCallback() {
        this.template = () => {
            const { changes, patient, has_changes, editable } = this;
            const birth_date = changes.birth_date || patient.birth_date;

            const care_team_ids = this.care_team?.map(team_member => team_member.id) || [];
            const filtered_clinicians = this.clinicians?.filter(clinician => !care_team_ids.includes(clinician.id)) || [];

            return html`
                <style>

                    button.accordion-button.collapsed {
                        background: #4e4f7f;
                        color: #ffffff73;
                    }

                    button.accordion-button:not(.collapsed) {
                        background: #353658;
                        color: white;
                    }
                    button.accordion-button:after {
                        filter: invert();
                    }

                    .offcanvas-header {background: #dbd9d9;}

                    .col-sm-1.drawer-btn span {
                        font-size: 16px;
                        background: #4e4f7f;
                        padding: 5px;
                        color: white;
                        border-radius: 4px;
                    }

                    .hr {
                        width: 115%;
                        margin: 19px 0px 10px 20px;
                        border-bottom: 1px solid #dfdfdf;
                        position: relative;
                    }

                    div#offcanvasScrolling {
                        box-shadow: 0px 0px 80px 0px rgb(0 0 0 / 58%);
                        border-left: 1px solid #00000029;
                    }


                    app-patient-profile .editable_item {
                        position: relative;
                    }
                    app-patient-profile .editable_item_confirm {
                        font-size: 24px;
                        color: var(--t-color-primary);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }
                    app-patient-profile .edit_icon {
                        margin-left: 10px;
                        font-size: 18px;
                        width: 20px;
                        color: var(--t-color-primary);
                        cursor: pointer;
                    }
                    app-patient-profile .error_message {
                        font-size: 14px;
                        color: var(--t-color-danger);
                    }
                    app-patient-profile .form-label {
                        font-size: 13px;
                        font-weight: 700;
                        color: var(--t-color-dark);
                        margin-bottom: 0px;
                        margin-top: 10px;
                    }
                    app-patient-profile .row {
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    app-patient-profile .accordion-collapse {
                        margin-bottom: 10px;
                    }

                    .accordian-item {
                        background: #f7efdb;
                    }

                </style>


                <div class="accordion accordion-flush" autofocus>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panel_contact" aria-expanded="true" aria-controls="panel_contact">
                               Contact Information
                            </button>
                        </h2>
                        <div id="panel_contact" class="accordion-collapse show">
                            <app-patient-contact-info .patient=${patient}></app-patient-contact-info>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panel_demographics" aria-expanded="false" aria-controls="panel_demographics">
                                Demographics
                            </button>
                        </h2>
                        ${this.demographicsPanel(patient)}
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panel_clinical" aria-expanded="false" aria-controls="panel_clinical">
                                Clinical Information
                            </button>
                        </h2>
                        ${this.clinicalInformationPanel(patient)}
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panel_careteam" aria-expanded="false" aria-controls="panel_careteam">
                                Care Team
                            </button>
                        </h2>
                        ${this.careTeamPanel(patient)}
                    </div>
                </div>
            `}

        this.render();
        this.init();
    }

    render() {
        if (!this.template) return;
        if (!this.patient) return;

        render(this.template(), this);
    }

    async init() {
        let elements = this.querySelectorAll(".editable_item");
        for (let element of elements) {
            element.addEventListener("mouseover", () => {
                let edit_icon = element.querySelector(".edit_icon");
                if (!edit_icon)
                    return;
                edit_icon.style.display = "inline";
            });
            element.addEventListener("mouseout", () => {
                let edit_icon = element.querySelector(".edit_icon");
                if (!edit_icon)
                    return;
                edit_icon.style.display = "none";

            });
            element.addEventListener("click", (e) => {
                let edit_prop = e.currentTarget.dataset.editProp;
                this[edit_prop] = true;
                this.render();

                let edit_element = this.querySelector("input,select");
                if (edit_element)
                    edit_element.focus();

            });
            element.addEventListener("edit_complete", e => {
                element.classList.remove("edit_error");
                let error_element = element.querySelector(".error_message");
                error_element.innerHTML = '';
                let edit_prop = e.currentTarget.dataset.editProp;
                this[edit_prop] = false;
                this.render();
            });
            element.addEventListener("edit_error", e => {
                let err = e.detail;
                element.classList.add("error");
                let error_element = element.querySelector(".error_message");
                if (!error_element)
                    return;
                error_element.innerHTML = err.message;
            });
        }
        await this.loadClinicians();
        await this.loadPatientCareTeam();
    }

    demographicsPanel(patient) {
        return html`
            <div id="panel_demographics" class="accordion-collapse collapse">
                <div class="row editable_item" 
                    style="margin-top: 0px;"
                    @keydown=${e => this.handleKeypress("name_editing", e)}
                    data-edit-prop="name_editing">
                    <label class="form-label">Name</label>
                    ${this.name_editing ?
                html`
                            <div class="col-sm-5">
                                <label for="profile-first_name" class="form-label">First Name</label>
                                <input type="text"
                                    id="profile-first_name"
                                    class="form-control"
                                    name="first_name"
                                    .value=${patient.first_name || ''}
                                    placeholder="first name"
                                    @input=${e => this.handleChange("first_name", e.target.value)}
                                    />
                            </div>
                            <div class="col-sm-6">
                                <label for="profile-last_name" class="form-label">Last Name</label>
                                <input type="text"
                                    id="profile-last_name"
                                    class="form-control"
                                    name="last_name"
                                    .value=${patient.last_name || ''}
                                    placeholder="last name"
                                    @input=${e => this.handleChange("last_name", e.target.value)}
                                    />
                            </div>
                            <div class="col-sm-1 material-symbols-outlined editable_item_confirm"
                                @click=${e => this.patch({ first_name: this.changes.first_name, last_name: this.changes.last_name }, e.target)}
                            >check_circle</div>
                            <div class="error_message"></div>
                        ` :
                html`
                            <div class="col" style="display: flex; align-items: center">
                                ${patient.first_name} ${patient.last_name}
                                <span style="display: none" class="edit_icon material-symbols-outlined">edit</span>
                            </div>
                        `
            }
                </div>
                <div class="row editable_item" 
                    @keydown=${e => this.handleKeypress("sex_editing", e)}
                    data-edit-prop="sex_editing">
                    <label class="form-label">Sex</label>
                    ${this.sex_editing ?
                html`
                            <div class="col-sm-11">
                                <select id="profile-sex" class="form-select" name="sex" @change=${(e) => this.handleChange("sex", e.target.value)}>
                                    <option value="male" ?selected=${patient.sex === "male"}>Male</option>
                                    <option value="female" ?selected=${patient.sex === "female"}>Female</option>
                                    <option value="unknown" ?selected=${patient.sex === "unknown"}>Unknown</option>
                                </select>
                            </div>
                            <div class="col-sm-1 material-symbols-outlined editable_item_confirm"
                                @click=${e => this.patch({ sex: this.changes.sex }, e.target)}
                            >check_circle</div>
                            <div class="error_message"></div>
                        ` :
                html`
                            <div class="col" style="text-transform: capitalize; display: flex; align-items: center;">
                                ${patient.sex}
                                <span style="display: none" class="edit_icon material-symbols-outlined">edit</span>
                            </div>
                        `
            }
                </div>
                <div class="row editable_item" 
                    @keydown=${e => this.handleKeypress("birthdate_editing", e)}
                    data-edit-prop="birthdate_editing">
                    <label class="form-label">Birth date</label>
                    ${this.birthdate_editing ?
                html`
                            <div class="col-sm-11">
                                <input type="date"
                                    id="profile-birth_date"
                                    class="form-control"
                                    name="birth_date"
                                    .value=${patient.birth_date instanceof Date ? format(patient.birth_date, "yyyy-MM-dd") : patient.birth_date}
                                    placeholder="first name"
                                    @input=${(e) => this.handleChange("birth_date", e.target.value)} />
                            </div>
                            <div class="col-sm-1 material-symbols-outlined editable_item_confirm"
                                @click=${e => this.patch({ birth_date: this.changes.birth_date }, e.target)}
                            >check_circle</div>
                            <div class="error_message"></div>
                        ` :
                html`
                            <div class="col" style="display: flex; align-items: center">
                                ${format(parseISODateWithoutTime(patient.birth_date), "MM/dd/yyyy")}
                                <span style="display: none" class="edit_icon material-symbols-outlined">edit</span>
                            </div>
                        `
            }
                </div>
                <div class="row">
                    <label class="form-label">Medicare ID</label>
                    <div class="col-sm-8">${patient.medicare_id}</div>
                </div>
                <div class="row">
                    <label class="form-label">MRN</label>
                    <div class="col-sm-8">${patient.mrn}</div>
                </div>
                ${this.patient.address_line_1 ?
                html`
                        <div class="row">
                            <label class="form-label">Address</label>
                            <div>${this.patient.address_line_1}</div>
                            <div>${this.patient.address_line_2}</div> 
                            <div>${this.patient.city}, ${this.patient.state} ${this.patient.zip_code}</div>
                        </div>
                    `: ''
            }
            </div>
        `;
    }

    clinicalInformationPanel(patient) {
        return html`
            <div id="panel_clinical" class="accordion-collapse collapse">
                <div class="row">
                    <label class="form-label">Primary Diagnosis</label>
                    <div class="col-sm-8">${patient.primary_diagnosis || 'Unknown'}</div>
                </div>
                <div class="row">
                    <label class="form-label">Secondary Diagnosis</label>
                    <div class="col-sm-8">${patient.secondary_diagnosis || 'Unknown'}</div>
                </div>
                ${this.patient.triage ?
                html`
                        <div class="row">
                            <label class="form-label">Triage</label>
                            <div class="col-sm-8">${patient.triage}</div>
                        </div>
                    `: ''}
                ${this.patient.disaster_code ?
                html`
                        <div class="row">
                            <label class="form-label">Disaster Code</label>
                            <div class="col-sm-8">${patient.disaster_code}</div>
                        </div>
                    `: ''}
                ${this.patient.pharmacy_name ?
                html`
                        <div class="row">
                            <label class="form-label">Pharmacy Information</label>
                            <div class="col-sm-8">
                                ${patient.pharmacy_name} 
                                <a href=${"tel:" + patient.pharmacy_number}>${patient.pharmacy_number}</a>
                            </div>
                        </div>
                    `: ''}
                ${this.patient.clinical_summary ?
                html`
                        <div class="row">
                            <label class="form-label">Clinical Summary</label>
                            <div class="col-sm-8">${patient.clinical_summary}</div>
                        </div>
                    `: ''}
            </div>
        `
    }

    careTeamPanel(patient) {
        return html`
            <div id="panel_careteam" class="accordion-collapse collapse">
                ${patient.prescribing_physician_name ?
                html`
                        <div class="row editable_item">
                            <label class="form-label">Physician</label>
                            <div class="col-sm-4" style="display: flex; align-items: center">
                                <span>${patient.prescribing_physician_name}</span>
                            </div>
                            <div class="col-sm-8" style="display: flex; align-items: center">
                                <span class="material-symbols-outlined" style='
                                    font-size: 1rem;
                                    margin-right: 7px;
                                    color: var(--t-color-primary);
                                    font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                                '>phone</span>
                                <a href="tel:${patient.prescribing_physician_phone}">${patient.prescribing_physician_phone}</a></div>
                            </div>
                        </div>
                    `: ''}
                <div class="row editable_item" @click=${e => this.handleEditCareteam(e)} >
                    <label class="form-label col-sm-4">Primary Clinician</label>
                    <div class="col-sm-8" style="display: flex; align-items: center">
                        ${patient.primary_clinician_id ?
                patient.primary_clinician_id.first_name + " " + patient.primary_clinician_id.last_name
                : "Not set"}
                        <span style="display: none" class="edit_icon material-symbols-outlined">edit</span>
                    </div>
                </div>
                <div class="row editable_item" @click=${e => this.handleEditCareteam(e)} >
                    <label class="form-label col-sm-4">Case Manager</label>
                    <div class="col-sm-8" style="display: flex; align-items: center">
                        ${patient.case_manager_id ?
                patient.case_manager_id.first_name + " " + patient.case_manager_id.last_name
                : "Not set"}
                        <div style="display: none" class="edit_icon material-symbols-outlined">edit</div>
                    </div>
                </div>
            </div>
        `
    }

    async handleEditCareteam(e) {
        let modal = new AppPatientCareTeamModal();
        modal.patient = this.patient;
        await modal.showModal();
        let { patient, should_update } = await modal.onDidDismiss();
        this.patient = patient;
    }

    handleChange(field, value) {
        this.changes[field] = value;
    }

    handleKeypress(edit_group, e) {
        if (e.key == 'Escape') {
            e.preventDefault();
            e.stopImmediatePropagation();
            this[edit_group] = false;
            this.render();
        }
    }

    async patch(patch_body, element) {
        try {
            let result = await directus.items("patient").updateOne(this.patient.id, patch_body, {
                fields: [
                    '*',
                    'current_episode_id.*',
                    'client_id.*',
                    'case_manager_id.*',
                    'primary_clinician_id.*',
                ]
            });
            this.patient = result;
        }
        catch (err) {
            console.error(err);
            element.dispatchEvent(
                new CustomEvent("edit_error", { bubbles: true, detail: err })
            );
        }
        element.dispatchEvent(
            new CustomEvent("edit_complete", { bubbles: true })
        );
    }

    async expandPatient() {
        if (
            typeof this.patient.primary_clinician_id !== "object" ||
            typeof this.patient.case_manager_id !== "object"
        ) {
            this._patient = await directus.items("patient").readOne(this._patient.id, {
                fields: [
                    '*',
                    'current_episode_id.*',
                    'client_id.*',
                    'case_manager_id.*',
                    'primary_clinician_id.*',
                ]
            });
            this.render();
        }
    }

    async handleConfirmClick() {
        try {
            if (Object.keys(this.patient_access_changes).length > 0) {
                const to_remove = [];
                const to_add = [];
                for (const id in this.patient_access_changes) {
                    const action = this.patient_access_changes[id];

                    switch (action) {
                        case "remove":
                            to_remove.push(id);
                            break;
                        case "add":
                            const access_record = this.patient_access.find((i) => i.user_id === id);
                            // It already exists, and we've staged and unstaged a remove
                            if (access_record) break;
                            to_add.push({
                                patient_id: this.patient.id,
                                user_id: id,
                            });
                            break;
                    }
                }

                if (to_remove.length) {
                    await directus.items("patient_access").deleteMany(to_remove);
                    for (const id of to_remove) {
                        const pa = this.patient_access.find((i) => i.id === id);
                        if (
                            this.patient.case_manager_id === pa?.user_id?.id ||
                            this.patient.case_manager_id?.id === pa?.user_id?.id
                        ) {
                            this.changes.case_manager_id = null;
                        }
                        if (
                            this.patient.primary_clinician_id === pa?.user_id.id ||
                            this.patient.primary_clinician_id?.id === pa?.user_id.id
                        ) {
                            this.changes.primary_clinician_id = null;
                        }
                    }
                }

                if (to_add.length)
                    await directus.items("patient_access").createMany(to_add);
            }

            // If we have any patient changes, save those.
            if (Object.keys(this.changes).length > 0) {
                if (this.changes?.primary_clinician_id?.id)
                    this.changes.primary_clinician_id = this.changes.primary_clinician_id.id;
                if (this.changes?.case_manager_id?.id)
                    this.changes.case_manager_id = this.changes.case_manager_id.id;

                const res = await directus.items("patient").updateOne(this.patient.id, this.changes, {
                    fields: [
                        '*',
                        'current_episode_id.*',
                        'client_id.*',
                    ]
                });

                this.changes = {};
            }
        } catch (err) {
            console.error(err);
            this.error_message = err.message;
            return;
        }

        // reload the patient to make sure that all
        this.patient = await Cache.getPatient(this.patient.id, true);
        this.dismiss({
            should_update: this.editable,
            patient: this.patient,
        });
    }

    async loadClinicians(force) {
        if (force !== true && this.clinicians) return;
        // only load once unless force is set to true
        try {
            const client_id = typeof this.patient.client_id === "string" ? this.patient.client_id : this.patient.client_id.id;
            const res = await directus.users
                .readByQuery({
                    filter: {
                        client_access: {
                            client_id: client_id,
                        },
                        role: {
                            name: {
                                _in: ["Clinician", "Client"],
                            }
                        }
                    },
                    fields: ["*", "role.*", "client_access.*"],
                });

            const clinicians = [];
            const case_managers = [];

            for (const item of res.data) {
                clinicians.push(item);
                case_managers.push(item);
            }

            this.clinicians = clinicians;
            this.case_managers = case_managers;

            this.render();
        } catch (err) {
            console.error(err);
            this.clinicians = [];
        }
    }

    async loadPatientCareTeam(force) {
        if (!force && this.care_team) return;
        try {
            const res = await directus.items("patient_access").readByQuery({
                filter: {
                    patient_id: this.patient.id,
                },
                fields: ["id", "user_id.*"],
            });

            const client_id = getCurrentClientId();
            this.patient_access = res.data;
            this.care_team = res.data.map((i) => i.user_id);
        } catch (err) {
            console.error(err);
            this.care_team = [];
        }
    }
}

customElements.define("app-patient-profile", AppPatientProfile);