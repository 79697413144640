import { html, render } from "lit";
import directus from "../../lib/lib-directus";

export default class AppPatientContactInfo extends HTMLElement {
    set patient(value) {
        if (value) {
            value.primary_phone = this.formatPhoneNumber(value.primary_phone);
            value.secondary_phone = this.formatPhoneNumber(value.secondary_phone);
            value.sms_phone = this.formatPhoneNumber(value.sms_phone);
            value.preferred_phone = this.formatPhoneNumber(value.preferred_phone);
        }
        this._patient = value;
        this.render();
    }

    get patient() {
        return this._patient;
    }

    constructor() {
        super();
        this.changes = {};
    }

    connectedCallback() {
        this.render();
    }

    render() {
        if (!this.patient) return;

        const template = html`
<style>
#panel_contact .col-sm-8 {
    gap: 8px;
    margin: 5px 0 5px 0;
}

#panel_contact .col-sm-8 span.phone-icon {
    font-size: 15px;
    font-weight: 900;
}

#panel_contact .col-sm-8 a {
    text-decoration: none;
    font-weight: 600;
}

app-patient-contact-info > div {
    padding: 0 0 0 10px;
}
                </style>

            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <div>
                    ${this.renderPhoneField('Preferred Phone', 'preferred_phone')}
                    ${this.renderPhoneField('SMS Phone', 'sms_phone')}
                    <div class="hr"></div>
                    ${this.renderPhoneField('EHR Primary Phone', 'primary_phone')}
                    ${this.renderPhoneField('EHR Secondary Phone', 'secondary_phone')}
                    
                    
                    ${this.renderEmergencyContact()}
                </div>
                ${this.renderEditButton()}
            </div>
        `;
        render(template, this);
    }

    renderPhoneField(label, field) {
        const editing = this[`${field}_editing`];
        const value = this.patient[field];

        return html`
            <div class="row">
                <label class="form-label">${label}</label>
                ${editing ?
                html`
                        <div class="col-sm-11">
                            <input type="tel"
                                id="profile-${field}"
                                class="form-control"
                                name="${field}"
                                .value=${value || ''}
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                placeholder="${label}"
                                @input=${(e) => this.handleChange(field, e)} />
                        </div>
                        <div class="error_message"></div>
                    ` :
                html`
                        <div class="col-sm-8" style="display: flex; align-items: center">
                            <span class="material-symbols-outlined phone-icon">phone</span>
                            <a href="tel:${value || ''}">${value ? this.formatPhoneNumber(value) : 'none provided'}</a>
                        </div>
                    `
            }
            </div>
        `;
    }

    renderEmergencyContact() {
        const patient = this.patient;
        return html`
            <div class="row">
                <label class="form-label">Emergency Contact Name</label>
                ${this.emergency_contact_name_editing ?
                html`
                        <div class="col-sm-11">
                            <input type="text"
                                id="profile-emergency_contact_name"
                                class="form-control"
                                name="emergency_contact_name"
                                .value=${patient.emergency_contact_name || ''}
                                placeholder="Emergency contact name"
                                @input=${(e) => this.handleChange("emergency_contact_name", e.target.value)} />
                        </div>
                        <div class="error_message"></div>
                    ` :
                html`
                        <div class="col-sm-8" style="display: flex; align-items: center">
                            <span>${patient.emergency_contact_name}</span>
                        </div>
                    `
            }
            </div>
            <div class="row">
                <label class="form-label">Emergency Contact Relationship</label>
                ${this.emergency_contact_relationship_editing ?
                html`
                        <div class="col-sm-11">
                            <input type="text"
                                id="profile-emergency_contact_relationship"
                                class="form-control"
                                name="emergency_contact_relationship"
                                .value=${patient.emergency_contact_relationship || ''}
                                placeholder="Emergency contact relationship"
                                @input=${(e) => this.handleChange("emergency_contact_relationship", e.target.value)} />
                        </div>
                        <div class="error_message"></div>
                    ` :
                html`
                        <div class="col-sm-8" style="display: flex; align-items: center">
                            <span>${patient.emergency_contact_relationship}</span>
                        </div>
                    `
            }
            </div>
            <div class="row">
                <label class="form-label">Emergency Contact Phone</label>
                ${this.emergency_contact_phone_number_editing ?
                html`
                        <div class="col-sm-11">
                            <input type="tel"
                                id="profile-emergency_contact_phone_number"
                                class="form-control"
                                name="emergency_contact_phone_number"
                                .value=${patient.emergency_contact_phone_number || ''}
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                placeholder="Emergency contact phone"
                                @input=${(e) => this.handleChange("emergency_contact_phone_number", e)} />
                        </div>
                        <div class="error_message"></div>
                    ` :
                html`
                        <div class="col-sm-8" style="display: flex; align-items: center">
                            <span class="material-symbols-outlined phone-icon">phone</span>
                            <a href="tel:${patient.emergency_contact_phone_number}">${patient.emergency_contact_phone_number}</a>
                        </div>
                    `
            }
            </div>
        `;
    }

    renderEditButton() {
        const isEditing = this.emergency_contact_name_editing ||
            this.primary_phone_editing ||
            this.secondary_phone_editing ||
            this.sms_phone_editing ||
            this.preferred_phone_editing ||
            this.emergency_contact_relationship_editing ||
            this.emergency_contact_phone_number_editing;

        return html`
            <div style="margin-left: auto; color: blue; cursor: pointer; padding: 1rem;">
                <div class="editable_item"
                    @keydown=${e => this.handleKeypress(e)}
                    data-edit-prop="all">
                    ${isEditing ?
                html`
                            <div class="col-sm-1 drawer-btn"
                                @click=${e => this.handleSave(e)}>
                                <span class="material-symbols-outlined"> save </span>
                            </div>
                            <div class="error_message"></div>
                        ` :
                html`
                            <div class="col-sm-1 drawer-btn"
                                @click=${() => this.enableEditing()}>
                                <span class="material-symbols-outlined"> edit </span>
                            </div>
                        `
            }
                </div>
            </div>
        `;
    }

    handleChange(field, event) {
        const input = event.target;
        const value = input.value;

        if (field.includes("phone")) {
            const digits = value.replace(/\D/g, '');
            const formatted = digits.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            const displayValue = !formatted[2] ? formatted[1] : `(${formatted[1]}) ${formatted[2]}-${formatted[3] ? formatted[3] : ''}`;
            input.value = displayValue;
            this.changes[field] = digits;
        } else {
            this.changes[field] = value;
        }
    }

    handleKeypress(e) {
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopImmediatePropagation();
            this.disableEditing();
            this.render();
        }
    }

    enableEditing() {
        this.emergency_contact_name_editing = true;
        this.primary_phone_editing = true;
        this.secondary_phone_editing = true;
        this.sms_phone_editing = true;
        this.preferred_phone_editing = true;
        this.emergency_contact_relationship_editing = true;
        this.emergency_contact_phone_number_editing = true;
        this.render();
    }

    disableEditing() {
        this.emergency_contact_name_editing = false;
        this.primary_phone_editing = false;
        this.secondary_phone_editing = false;
        this.sms_phone_editing = false;
        this.preferred_phone_editing = false;
        this.emergency_contact_relationship_editing = false;
        this.emergency_contact_phone_number_editing = false;
    }

    async handleSave(e) {
        try {
            const result = await directus.items("patient").updateOne(this.patient.id, this.changes, {
                fields: [
                    '*',
                    'current_episode_id.*',
                    'client_id.*',
                    'case_manager_id.*',
                    'primary_clinician_id.*',
                ]
            });
            this.patient = result;
            this.changes = {};
            this.disableEditing();
            this.render();
            e.target.dispatchEvent(new CustomEvent("edit_complete", { bubbles: true }));
        } catch (err) {
            console.error(err);
            e.target.dispatchEvent(new CustomEvent("edit_error", { bubbles: true, detail: err }));
        }
    }

    formatPhoneNumber(phoneNumber) {
        if (!phoneNumber) return '';
        const digits = phoneNumber.replace(/\D/g, '');
        if (digits.length === 10) {
            return digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
        return phoneNumber;
    }
}

customElements.define("app-patient-contact-info", AppPatientContactInfo);