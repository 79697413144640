import { html, render } from "lit";
import ModalBase from "../util/modal-base";
import AppPatientSchedules from "./app-patient-schedules";

export default class AppPatientSchedulesModal extends ModalBase {
    static get properties() {
        return {
            patient_id: { type: String },
            patient_name: { type: String }
        };
    }

    constructor() {
        super();
        this.patient_id = null;
        this.patient_name = '';
    }

    connectedCallback() {
        this.template = () => {
            return html`
            <style>
                .modal-title {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-weight: 300;
                }
                .modal-title .material-symbols-outlined {
                    font-size: 20px;
                    color: #ffffff;
                    font-weight: 300;
                }
                .modal-dialog {
                    max-width: 800px; /* Wider modal for better list view */
                }
            </style>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" id="patient-schedule-modal">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="material-symbols-outlined">pending_actions</span>
                            Manage Future Scheduled Items - ${this.patient_name}
                        </h5>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <app-patient-schedules
                            .patient_id=${this.patient_id}
                        ></app-patient-schedules>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary light-gray-btn" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
            `;
        };

        this.render();
        this.addEventListener("shown.bs.modal", this.render);
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }
}

customElements.define("app-patient-schedules-modal", AppPatientSchedulesModal);
