import { html, render, nothing } from "lit";
import Tagify from "@yaireo/tagify";

/**
 * @typedef {Object} ChipSortField
 * @property {string} column
 * @property {string} direction - either 'asc' or desc'
 */

/**
 * A chip-style sort component which leverages tagify  https://github.com/yairEO/tagify
 *
 * @param {ChipSortField[]} sort_fields Current sort fields. This must be passed for sort to work properly.
 * @param {Object} sort_label_to_column mapping of displayed field labels to columns
 * @param {boolean} label_top if true, label is positioned absolutely above the main input
 * @fires AppChipSort#tagifyclick when one of the tagify sort tags/chips is clicked
 * @fires AppChipSort#tagifyadd when a new tagify sort tag/chip is added
 * @fires AppChipSort#tagifyremove when a new tagify sort tag/chip is removed
 */
export default class AppChipSort extends HTMLElement {
    constructor() {
        super();
        // Tagify gets confused if IDs are reused for reinstantiation
        this._input_id = "sort-input" + Math.random().toString(16).slice(2);
    }

    get sort_fields() {
        return this._sort_fields;
    }
    set sort_fields(value) {
        this._sort_fields = value;
        this.render();
    }

    get sort_label_to_column() {
        return this._sort_label_to_column;
    }
    set sort_label_to_column(value) {
        this._sort_label_to_column = value;
        this.render();
    }

    /**
     * Initializes the Tagify chip input
     */
    initTagify() {
        const appChipSort = this;
        const sort_input = document.querySelector(`#${this._input_id}`);

        if (!sort_input) {
            console.error("Tagify input element not found");
            return;
        }

        this._tagify = new Tagify(sort_input, {
            whitelist: Object.keys(this.sort_label_to_column || {}), // Handle empty or undefined whitelist gracefully
            editTags: false,
            dropdown: {
                maxItems: 6,
            },
            templates: {
                tag: function (tag_data) {
                    try {
                        if (!tag_data || !tag_data.value) {
                            return /*html*/ `
                            <tag 
                                contenteditable="false"
                                spellcheck="false" 
                                class="tagify__tag placeholder-tag" 
                            >
                                <span class='tagify__tag-text'>No Data</span>
                            </tag>
                        `;
                        }

                        const sort_field = appChipSort._sort_fields?.find(
                            (field) => field.column === appChipSort.sort_label_to_column?.[tag_data.value]
                        );

                        const directionIcon = sort_field?.direction === "desc" ? "south" : "north";

                        return /*html*/ `
                            <tag 
                                contenteditable="false"
                                spellcheck="false" 
                                class="tagify__tag ${tag_data.class || ""}" 
                                ${this.getAttributes(tag_data)} 
                            >
                                <span class="material-symbols-outlined" style="
                                    font-size: 21px;
                                    color: var(--t-color-white);
                                    font-weight: 700;
                                ">
                                    ${directionIcon || ""} 
                                </span>
                                <x title='remove tag' class='tagify__tag__removeBtn' style="
                                    color: white; 
                                    height: 20px;
                                    font-size: 20px;
                                    width: 20px;"
                                ></x>
                                <div>
                                    <span class='tagify__tag-text'>${tag_data.value || "Unknown"}</span>
                                </div>
                            </tag>
                        `;
                    } catch (err) {
                        console.error("Error generating tag template:", err);
                        return /*html*/ `
                        <tag 
                            contenteditable="false"
                            spellcheck="false" 
                            class="tagify__tag error-tag" 
                        >
                            <span class='tagify__tag-text'>Error</span>
                        </tag>
                    `;
                    }
                },
            },
        });

        this._tagify.on("click", (event) => {
            const { data: tag_data, tag: tag_elem } = event.detail || {};
            if (!tag_data || !tag_elem) return; // Handle missing data gracefully
            this.dispatchEvent(new CustomEvent("tagifyclick", event));
            this._tagify.replaceTag(tag_elem, tag_data);
        });

        this._tagify.on("add", (event) => {
            const { data: tag_data } = event.detail || {};
            if (!tag_data) return; // Skip invalid data
            this.dispatchEvent(new CustomEvent("tagifyadd", event));
        });

        this._tagify.on("remove", (event) => {
            event.stopPropagation();
            this.dispatchEvent(new CustomEvent("tagifyremove", event));
        });
    }



    connectedCallback() {
        this.template = () =>
            html`<style>
                    .tagify {
                        display: flex;
                        align-items: center;
                        min-height: 38px;
                        border: 2px solid var(--t-color-grey-40);
                        --tag-bg: ${this._is_clinician_app ? "var(--t-color-primary)" : "var(--t-color-sidebar)"};
                        --tag-border-radius: 12px;
                        --tag-text-color: var(--t-color-white);
                    }
                    .tagify__input {
                        margin: unset;
                        height: 100%;
                        min-width: unset;
                    }
                    .tagify__tag,
                    .tagify__dropdown__item {
                        cursor: pointer;
                        height: 29px;
                        padding: 4px 10px;
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 14px;
                        letter-spacing: 0.5px;
                        margin: 4px;
                        display: flex;
                        align-items: center;
                        border-radius: 12px;
                        --tag-border-radius: 12px;
                        --tagify-dd-bg-color: var(--t-color-grey);
                        --tagify-dd-color-primary: var(--t-color-grey);
                    }
                    .tagify__tag:hover:not([readonly]) div::before {
                        --tag-bg-inset: unset;
                        --tag-bg: unset;
                    }
                    .tagify__dropdown__item {
                        width: fit-content;
                        background-color: var(--t-color-grey);
                        color: var(--t-color-white);
                        margin-top: 7px;
                    }
                    .tagify__dropdown__wrapper {
                        border-radius: 12px;
                        border: 2px solid var(--t-color-grey-40);
                        border-top-width: 0px;
                    }
                    label.position-top {
                        margin: 0 auto 2px 8px;
                    }
                    #sort-wrapper {
                        display: flex;
                        align-items: center;
                        position: relative;
                        flex-direction: ${this.label_top ? "column" : "row"};
                    }
                    #sort-wrapper label {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        color: var(--t-color-primary);
                        display: flex;
                        align-items: center;
                        text-align: center;
                        white-space: nowrap;
                        padding-right: 16px;
                    }
                </style>
                <div
                    class="d-flex"
                    id="sort-wrapper"
                    @click=${(e) => this.handleSortClick(e)}>
                    <label class=${this.label_top ? "position-top" : nothing}>Sort By</label>
                    <input 
                        id=${this._input_id}
                        class="form-control" 
                        value=${this.getInitialTagifyValue()}
                    />
                </div>
            `;

        Object.assign(this.style, {
            display: "block",
        });

        this.render();
        this.initTagify();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    getInitialTagifyValue() {
        return JSON.stringify(
            this.sort_fields.map(field => {
                const field_label = Object.entries(this.sort_label_to_column)
                    .find(([label, column]) => column === field.column)?.[0];
                return field_label ? { value: field_label } : null;
            })
        );
    }

    /**
     * Shows the tagify dropdown on sort input click. The default behavior is to show on input or focus.
     * @param {Event} event click event for sort container div
     */
    handleSortClick(event) {
        event.stopPropagation();
        if (event.target.isEqualNode(document.querySelector("span.tagify__input"))) {
            this._tagify.dropdown.show();
        }
    }
}

customElements.define("app-chip-sort", AppChipSort);
