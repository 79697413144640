import { html, render } from "lit";
import * as bootstrap from 'bootstrap';

/**
 * @typedef {object} DrawerOptions
 * @property {string} placement - start, top, bottom, end
 * @property {HTMLElement} component - the component to render in the drawer
 * @property {string} title - optional drawer title to display
 * @property {boolean} show_backdrop - whether the drawer should act like a modal
 */

/**
 * Drawer utility component
 */
class AppDrawer extends HTMLElement {
    constructor() {
        super();
        this._loading = false;
    }

    set options(value) {
        /** @type {DrawerOptions} */
        this._options = value;
        Object.assign(this, value); //assign props
        this.render();
    }

    /** @type {DrawerOptions} */
    get options() {
        return this._options;
    }

    get component() {
        return this._component;
    }

    set component(value) {
        // Set loading first
        this._loading = true;
        this.render();

        // Wait for next tick to set component
        Promise.resolve().then(() => {
            this._component = value;

            // Wait a bit longer before removing loading state
            setTimeout(() => {
                this._loading = false;
                this.render();
            }, 300); // Give more time for component to initialize
        });
    }

    get placemenet() {
        return this._placement;
    }

    set placement(value) {
        this._placement = value || 'end';
    }

    get show_backdrop() {
        return this._show_backdrop;
    }

    set show_backdrop(value) {
        this._show_backdrop = value;
    }

    get title() {
        return this._title || '';
    }

    set title(value) {
        this._title = value;
    }

    connectedCallback() {
        this.template = () => html`
        <div class="offcanvas offcanvas-${this.placemenet}" data-bs-scroll="true" data-bs-backdrop="${(!!this.show_backdrop).toString()}" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" autofocus>
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="app_drawer_${this.placemenet}">${this.title}</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body" style="padding: 0px;">
                ${this._loading ? html`
                    <div class="d-flex justify-content-center align-items-center" style="height: 100%;">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ` : this.component}
            </div>
        </div>
        `;

        this.render();
    }

    render() {
        if (!this.template)
            return;
        render(this.template(), this);
    }

    show() {
        let element = this.querySelector(".offcanvas");
        let instance = bootstrap.Offcanvas.getOrCreateInstance(element);
        instance.toggle();
    }
}

customElements.define("app-drawer", AppDrawer);
export default AppDrawer;
