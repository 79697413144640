import { html, LitElement, render } from "lit";
import { customElement, property } from 'lit/decorators.js';
import "../app-schedule-filter";
import "../app-chip-sort";

const sort_label_to_column = {
    "LAST SURVEY DATE": "last_survey_date",
    "BIRTH DATE": "birth_date",
    "FIRST NAME": "first_name",
    "LAST NAME": "last_name",
    MRN: "mrn",
    "SOC DATE": "current_episode_id.start_date",
    STATUS: "status",
};

/**
 * Supports searching and filtering a list of patients.
 *
 * @param {Array} sort_fields [{column: string, direction: 'asc'|'desc'}, ...]
 *      This must be passed for sort to work properly.
 * @fires AppPatientFilters#patientsearch input event on the patient search input
 * @fires AppPatientFilters#activetoggle active toggle change event for <app-toggle-switch>
 * @fires AppPatientFilters#tagifyclick when one of the tagify sort tags/chips is clicked
 * @fires AppPatientFilters#tagifyadd when a new tagify sort tag/chip is added
 * @fires AppPatientFilters#tagifyremove when a new tagify sort tag/chip is removed
 */
export default class AppPatientFilters extends HTMLElement {

    constructor() {
        super();
        this._count = 0;
        this._sort_fields = [];
        this._isActive = true;
        this._isExpired = false;
        this.updateTextProperties();
        this.toggleSearchModal = this.toggleSearchModal.bind(this);
        this.filters = {};

        // Dispatch initial episode status event
        this.dispatchEvent(new CustomEvent('episodestatustoggle', {
            detail: {
                isActive: true,
                isExpired: false
            },
            bubbles: true,
            composed: true
        }));
    }
    get count() {
        return this._count;
    }

    set count(value) {
        if (this._count !== value) {
            this._count = value;
            this.updateTextProperties();
            this.render();
        }
    }
    get sort_fields() {
        return this._sort_fields;
    }
    /**
     * @param {Array} value  array of objects with {column: string, direction: 'asc'|'desc'}
     * @todo typedef?
     */
    set sort_fields(value) {
        this._sort_fields = value;
    }

    get active() {
        return this._isActive;
    }

    set active(value) {
        if (this._isActive !== value) {
            this._isActive = value;
            this.updateTextProperties();
            this.render();
        }
    }

    emitViewChangeEvent(newView) {
        this.dispatchEvent(new CustomEvent("viewchange", {
            detail: { view: newView },
            bubbles: true,
            composed: true
        }));
        this.view = newView; // Update the view state
        this.render(); // Explicitly re-render the component
    }

    connectedCallback() {
        this.updateTextProperties();
        Promise.resolve().then(() => {
            if (this._count === undefined) {
                this.count = 0; // or whatever default value is appropriate
            }
        });
        //console.log("sort fields", this.sort_fields);
        this.template = () =>

            html`<style>
                    app-patient-filters #patients-nav input {
                        margin-left: 16px;
                        /*background: #4e4f7f;*/
                    }
                    app-patient-filters #search-wrapper {
                       
                    }
                    .form-check {
    padding: 0;
}

label.form-check-label {
    text-transform: uppercase;
    font-size: 13px;
}
                    app-patient-filters .search-input {
                        /*--tag-bg: var(--t-color-grey);
                        --tag-border-radius: 12px;
                        --tag-text-color: var(--t-color-white);*/
                    }
                    app-patient-filters app-chip-sort {
                        margin-top: 16px;
                    }

                    .search-input {
                        /*display: flex;
                        align-items: center; 
                        justify-content: center;*/
                    }
                    .form-check.form-switch {
                        margin: 0 !important;
                        padding: 0 !important;
                    }

                    .toggle-sm {
                            display: none !important;
                        }

                    .app-chip-sm {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 10px;
                    }

                    .p-sort {
                        margin-left: -12px;
                    }

                    .p-toggle-wrap-sm {
                            /*display: none;*/
                        }

                    .search-icon-sm:hover {
                        cursor: pointer;
                    }


                    @media (min-width: 768px) {
                        app-patient-filters app-chip-sort {
                            padding-left: 15px;
                            margin-top: unset;
                        }
                        /* app-patient-filters #patients-nav input {
                            flex-grow: unset;
                        } */
                    }
                    
                    .patient-wrapper {
                        display: flex;
                        align-items: center;
                    }

                    .search-input {
                     width: 100%;
                     }

                     .search-modal {
                    display: none;
                    position: fixed;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.6);
                    z-index: 1000;
                    }

                    .search-modal-content {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -140%);
                        background: white;
                        padding: 20px;
                        border-radius: 5px;
                        width: 90%;
                        position: relative;
                    }

                    .modal-top-bar {
                        position: absolute;
                        top: 0px;
                        right: 20px;
                    }
                        label.form-check-label {
                       color: white;
                       margin: 1px 0 0 6px;
                    }

                    .modal-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: 40px;
                    }

                    .search-input {
                        margin-right: 10px;
                    }
                    .search-modal.show {
                        display: block;
                    }

                    .search-icon-sm {
                        display: none;
                    }

                    .search-modal-content .modal-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .close-modal {
                        font-size: 40px;
                        cursor: pointer;
                    }
                    
                    @media only screen and (max-width: 768px) {
                    .p-page-head h2 {
                    font-size: 18px;
                    flex-direction: column;
                    align-items: flex-start;
                    }

                    .p-toggle-wrap {
                    flex-direction: column;
                    align-items: flex-start;
                    margin: 10px 0;
                    }

                    #search-wrapper {
                    flex-direction: column;
                    align-items: flex-start;
                    border: none;
                    }

                    .search-wrapper {
                        border: none;
                    }
                        
                    app-chip-sort {
                    width: 100%;
                    margin-top: 10px;
                    }
                     }   


                     @media (max-width: 650px) {
                        .search-input {
                            width: 100%;
                        }

                        .search-icon-sm {
                        display: block;
                        }

                        .search-icon-lg {
                        display: none !important;
                        }

                        .page-head-mobile {
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                        }   

                        .p-toggle-wrap {
                            display: none !important;
                        }

                        .app-chip-sm {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                        }

                        .toggle-sm {
                            margin-top: 12px !important;
                            display: flex !important;
                        }

                        .toggle-lg {
                            display: none !important;
                        }

                        .p-sort {
                        margin-left: 0px;
                    }

                        .p-toggle-wrap-sm {
                            display: flex;
                        }
                    }

                    .toggle-container {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                    }
                </style>

                    <div class="search-modal">
                        <div class="search-modal-content">
                            <h3>Search Patient</h3>
                            <div class="modal-top-bar">
                                <span class="close-modal" @click=${this.toggleSearchModal}>&times;</span>
                            </div>
                            <div class="modal-header">
                                <input
                                    class="form-control mr-sm-4 search-input"
                                    type="search"
                                    aria-label="Search Patients"
                                    @input=${(e) => this.handleSearch(e)}
                                />
                                <button class="search-modal-close btn btn-outline-secondary" @click=${this.toggleSearchModal}>Search</button>
                            </div>
                        </div>
                    </div>


                <nav class="mod-base dash-header" id="patients-nav">
                    <div class="patient-wrapper">
                    <div class="p-page-head" style="align-items: center;">
                        <h2 class="header-title">
                        <span class="material-symbols-outlined" style="margin-right: 5px">people</span>
                            Patients
                        </h2>
                    </div>
                                            
                    <div class="p-toggle-wrap toggle-container">

                     <!--   <app-toggle-switch
                            @toggle=${(e) => this.handleActiveToggle(e)}
                            .enabled=${this.active}
                            .enabled_text=${this.enabledText}
                            .disabled_text=${this.inactiveText}  
                            style="margin-left: 28px; background:white">
                        </app-toggle-switch> -->


                        <div class="toggle-container">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" id="activeToggle" name="episodeStatus"
                                       @change=${(e) => this.handleEpisodeStatusToggle(e)} 
                                       ?checked=${this._isActive}>
                                <label class="form-check-label" for="activeToggle">Active</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" id="expiredToggle" name="episodeStatus"
                                       @change=${(e) => this.handleEpisodeStatusToggle(e)} 
                                       ?checked=${this._isExpired}>
                                <label class="form-check-label" for="expiredToggle">Expired</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" id="allStatusToggle" name="episodeStatus"
                                       @change=${(e) => this.handleEpisodeStatusToggle(e)} 
                                       ?checked=${!this._isActive && !this._isExpired}>
                                <label class="form-check-label" for="allStatusToggle">All</label>
                            </div>
                        </div>

                    </div>
                    
                    </div>

                        <div 
                        id="search-wrapper" class="search-wrapper">
                        <div class="search-input">
                            <span
                            class="material-symbols-outlined"
                            >
                            search
                        </span>
                        <input
                        class="form-control mr-sm-4 search-input"
                        type="search"
                        aria-label="Search Patients"
                        @input=${(e) => this.handleSearch(e)}
                        />
                         </div>
                        </div>

                    <app-chip-sort
                        class="p-sort"
                        style=""
                        .sort_label_to_column=${sort_label_to_column}
                        .sort_fields=${this.sort_fields}
                        @tagifyclick=${(e) => this.handleTagifyClick(e)}
                        @tagifyadd=${(e) => this.handleTagifyAdd(e)}
                        @tagifyremove=${(e) => this.handleTagifyRemove(e)}
                    >
                    </app-chip-sort>

                     <!-- <div class="filter-container" style="align-items: center;">
                            <app-schedule-filter
                                .title=${"Start Date Range"}
                                .is_datepicker=${true}
                                .value=${this.filters.start_date_range || ''}
                                @dateselect=${e => this.handleDateFilterChange(e)}
                                @clear=${() => this.handleFilterClear("start_date_range")}
                            ></app-schedule-filter>
                        </div> -->

                    <div class="staff_dashboard_box" style="margin: 0 15px 0 15px;">
                        <h2 class="countup staff-stats">${this.count}</h2>
                        <h3>Filtered Count</h3>
                    </div>

<button
    class="btn light-purple-btn"
    id="reset-layout"
    style="margin-left: 10px;font-size: 10px; height: 35px;"
    @click=${() => this.handleClearTable()}
>
    RESET LAYOUT
</button>


                   <!--  <div class="btn-group view-toggle" id="toggle-container" role="group">
            <label class="btn btn-outline-secondary ${this.view === 'list' ? 'active' : ''}" @click=${() => this.emitViewChangeEvent('list')}>
                <span class="material-symbols-outlined">table_rows</span>
            </label>
            <label class="btn btn-outline-secondary ${this.view === 'card' ? 'active' : ''}" @click=${() => this.emitViewChangeEvent('card')}>
                <span class="material-symbols-outlined">view_cozy</span>
            </label>
        </div> -->
                </nav> `;

        Object.assign(this.style, {
            padding: "unset",
        });

        this.render();
        //this.updateTextProperties();
        //this.renderFilteredPatientCount();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
        this.updateTextProperties();
    }


    /**
        * Displays the count of patients matching the current filters
        * @returns lit html
    */
    /* renderFilteredPatientCount() {
        const { _filtered_count } = this;
        return html` <div
            class="row"
            style="
                padding: 24px 32px 0 32px;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                display: flex;
                align-items: center;
            ">
            <!-- Showing ${_filtered_count} patient${_filtered_count === 1 ? "" : "s"} -->
            <div class="col text-end" style="${this._appName !== 'staff' ? 'display:none;' : ''}">
                <span
                    @click=${_e => this.handlePatientAddClick(_e)}
                        class="material-symbols-outlined"
                        style='
                            font-size: 40px;
                            cursor: pointer;
                            margin-right: 7px;
                            color: var(--t-color-primary);
                            font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
                        '
                >
                add_circle
                </span>
            </div>
        </div>`;
    } */

    /**
     * @param {Event} event input event from the search <input>
     */
    toggleSearchModal() {
        const searchModal = this.querySelector('.search-modal');
        searchModal.classList.toggle('show');
    }

    handleClearTable() {
        this.dispatchEvent(new CustomEvent('cleartable', {
            bubbles: true,
            composed: true
        }));
    }


    handleSearch(event) {
        event.stopPropagation();
        this.dispatchEvent(new CustomEvent("patientsearch", { detail: event }));
    }

    handleTagifyClick(event) {
        this.dispatchEvent(new CustomEvent("tagifyclick", event))
    }
    handleTagifyAdd(event) {
        this.dispatchEvent(new CustomEvent("tagifyadd", event))
    }
    handleTagifyRemove(event) {
        this.dispatchEvent(new CustomEvent("tagifyremove", event))
    }

    handleActiveToggle(event) {
        const newActiveState = event.target.checked;
        this.active = newActiveState;
        this.updateTextProperties();
        this.dispatchEvent(new CustomEvent('activetoggle', {
            detail: newActiveState
        }));
        this.render();
    }

    handleScheduleToggle(event) {
        this._active = event.target.id === 'scheduleToggle';
        this._noScheduleActive = event.target.id === 'noScheduleToggle';
        this._allEpisodesActive = event.target.id === 'allEpisodesToggle';
        this.dispatchEvent(new CustomEvent('scheduletoggle', {
            detail: {
                hasSchedule: this._active,
                noSchedule: this._noScheduleActive,
                allEpisodes: this._allEpisodesActive
            },
            bubbles: true,
            composed: true
        }));
        this.render();
    }


    updateTextProperties() {
        this.enabledText = this.active ? `Has Schedule` : "Has Schedule";
        this.inactiveText = this.active ? "No Schedule" : `No Schedule`;
    }

    handleDateFilterChange(e) {
        const { start, end } = e.detail;
        if (start && end) {
            // Only set the date range if both dates are selected
            const startDate = start.toISOString().split('T')[0];
            const endDate = end.toISOString().split('T')[0];
            this.filters.start_date_range = `${startDate},${endDate}`;
        } else {
            // If either date is null, remove the date filter
            delete this.filters.start_date_range;
        }

        this.dispatchEvent(new CustomEvent('filterchange', {
            detail: { filters: this.filters },
            bubbles: true,
            composed: true
        }));
        this.render();
    }

    handleFilterClear(filterKey) {
        delete this.filters[filterKey];
        this.dispatchEvent(new CustomEvent('filterchange', {
            detail: { filters: this.filters },
            bubbles: true,
            composed: true
        }));
        this.render(); // Re-render to update the UI
    }

    handleEpisodeStatusToggle(event) {
        this._isActive = event.target.id === 'activeToggle';
        this._isExpired = event.target.id === 'expiredToggle';

        this.dispatchEvent(new CustomEvent('episodestatustoggle', {
            detail: {
                isActive: this._isActive,
                isExpired: this._isExpired
            },
            bubbles: true,
            composed: true
        }));
        this.render();
    }

}

customElements.define("app-patient-filters", AppPatientFilters);