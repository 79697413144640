import { html, render, LitElement } from "lit";
import directus from "../../lib/lib-directus";
import { format } from "date-fns";
import AppConfirmationModal from "../app-confirmation-modal";
import AppDeactivatePatientModal from "../app-deactivate-patient-modal";

export default class AppPatientSchedules extends LitElement {
    createRenderRoot() {
        return this;
    }
    static get properties() {
        return {
            patient_id: { type: String },
            tasks: { type: Array },
            communications: { type: Array },
            selectedItems: { type: Array }
        };
    }

    constructor() {
        super();
        this.tasks = [];
        this.communications = [];
        this.selectedItems = [];
        this.schedule_modal = '';
    }

    async connectedCallback() {
        super.connectedCallback();
        await this.loadItems();
    }

    async loadItems() {

        try {
            // Load all future tasks
            const tasksResponse = await directus.items("task").readByQuery({
                filter: {
                    patient_id: this.patient_id,
                    scheduled_date: { _gt: new Date().toISOString() },
                    status: { _nin: ["archived", "completed"] }
                },
                fields: [
                    'id',
                    'title',
                    'task_type',
                    'scheduled_date',
                    'patient_schedule_id.survey_schedule_id.name',
                    'patient_schedule_id.tags',
                    'status'
                ],
                sort: ['scheduled_date']
            });

            // Load all future communications
            const commsResponse = await directus.items("patient_communication").readByQuery({
                filter: {
                    patient_id: this.patient_id,
                    scheduled_date: { _gt: new Date().toISOString() },
                    status: { _nin: ["archived"] }
                },
                fields: [
                    'id',
                    'type',
                    'scheduled_date',
                    'patient_schedule_id.survey_schedule_id.name',
                    'patient_schedule_id.tags',
                    'status'
                ],
                sort: ['scheduled_date']
            });

            this.tasks = tasksResponse.data;
            this.communications = commsResponse.data;
            this.requestUpdate();
        } catch (error) {
            console.error("Error loading items:", error);
        }
    }

    handleItemSelect(item, event) {
        const itemId = `${item.type || 'task'}-${item.id}`;
        const index = this.selectedItems.findIndex(i => i.id === item.id && i.type === (item.type || 'task'));

        if (index === -1) {
            this.selectedItems = [...this.selectedItems, { id: item.id, type: item.type || 'task' }];
        } else {
            this.selectedItems = this.selectedItems.filter(i => !(i.id === item.id && i.type === (item.type || 'task')));
        }
        this.requestUpdate();
    }

    async handleRemoveItems() {
        if (!this.selectedItems.length) return;

        this.schedule_modal = document.getElementById("patient-schedule-modal");

        this.schedule_modal.classList.add("inactive");


        const modal = new AppDeactivatePatientModal();
        modal.itemCount = this.selectedItems.length;
        modal.schedule_modal = this.schedule_modal;
        await modal.showModal();

        const result = await modal.onDidDismiss();
        if (result?.confirmed) {
            try {
                const taskIds = this.selectedItems
                    .filter(item => item.type === 'task')
                    .map(item => item.id);

                const commIds = this.selectedItems
                    .filter(item => item.type === 'communication')
                    .map(item => item.id);

                if (taskIds.length) {
                    await directus.items("task").updateMany(taskIds, {
                        status: "archived",
                        status_reason: result.reason,
                        notes: result.notes
                    });
                }

                if (commIds.length) {
                    await directus.items("patient_communication").updateMany(commIds, {
                        status: "archived",
                        status_reason: result.reason,
                        notes: result.notes
                    });
                }

                this.selectedItems = [];
                await this.loadItems();
            } catch (error) {
                console.error("Error removing items:", error);
            }
        }
    }

    render() {

        if (this.schedule_modal) {
            this.schedule_modal.classList.remove("inactive");
        }

        const allItems = [
            ...this.tasks.map(t => ({ ...t, type: 'task' })),
            ...this.communications.map(c => ({ ...c, type: 'communication' }))
        ].sort((a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date));

        if (!allItems.length) {
            return html`
                <div class="alert alert-info">
                    No future tasks or communications found for this patient.
                </div>
            `;
        }

        return html`
            <style>
                table.schedule-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 16px;
                }
                .schedule-table th {
                    background: #f5f5f5;
                    padding: 8px;
                    text-align: left;
                    font-weight: 600;
                    border-bottom: 2px solid #ddd;
                    text-align: center;
                }
                .schedule-table td {
                    padding: 8px;
                    border-bottom: 1px solid #eee;
                }
                .schedule-table tr {
                    cursor: pointer;
                }
                .schedule-table tr:hover {
                    background-color: #f8f9fa;
                }
                .schedule-table tr.selected {
                    background-color: #e3f2fd;
                }
                .type-badge {
                    display: inline-block;
                    padding: 2px 6px 2px 6px;
                    border-radius: 3px;
                    font-size: 11px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                .schedule-name {
                    color: #666;
                    font-size: 0.9em;
                    margin-left: 4px;
                }
                .bulk-actions {
                    margin-bottom: 16px;
                }
                .tag {
                    display: inline-block;
                    padding: 2px 6px;
                    margin: 0 2px;
                    background: #e9ecef;
                    border-radius: 3px;
                    font-size: 11px;
                }
            </style>

            ${this.selectedItems.length > 0 ? html`
                <div class="bulk-actions">
                    <button 
                        class="btn btn-danger dark-red-btn"
                        @click=${this.handleRemoveItems}
                    >
                        Remove Selected (${this.selectedItems.length})
                    </button>
                </div>
            ` : ''}
                <div class="schedule-table-wrap">
            <table class="schedule-table">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Tags</th>
                        <th>Title/Description</th>
                        <th>Schedule</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    ${allItems.map(item => {
            const isSelected = this.selectedItems.some(
                i => i.id === item.id && i.type === item.type
            );
            const tags = item.patient_schedule_id?.tags || [];

            return html`
                            <tr class="${isSelected ? 'selected' : ''}"
                                @click=${(e) => this.handleItemSelect(item, e)}>
                                <td>
                                    <span class="type-badge bg-${item.type === 'task' ? 'primary' : 'success'} text-white">
                                        ${item.type === 'task' ? (item.task_type || 'Task') : 'Communication'}
                                    </span>
                                </td>
                                <td>
                                    ${tags.map(tag => html`
                                        <span class="tag">${tag}</span>
                                    `)}
                                </td>
                                <td>${item.title || item.type}</td>
                                <td>
                                    ${item.patient_schedule_id?.survey_schedule_id?.name || '-'}
                                </td>
                                <td>${format(new Date(item.scheduled_date), 'MMM d, yyyy')}</td>
                            </tr>
                        `;
        })}
                </tbody>
            </table>
    </div>
            `;
    }
}

customElements.define("app-patient-schedules", AppPatientSchedules);
