import { html, render } from "lit";
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap; //prevent tree shaking

import router from "shared/lib/lib-router.js";
import { getCurrentClientId } from "shared/lib/lib-user";
import ClientSettings from "shared/lib/lib-client-settings";

// Instantiates WS singleton
// import directusWebsocket from "shared/lib/lib-directus-websocket";

import "./app-header";
import "shared/components/app-login";
import "shared/components/app-password-reset";
import "shared/components/app-noclient";
import "shared/components/app-sidebar";
import "shared/components/app-drawer";
import "shared/components/app-toaster";

// Top-level Routes
import "../scenes/scene-dashboard-overview";
import "shared/scenes/scene-patients";
import "../scenes/scene-reports";
import "../scenes/scene-import";
import "../scenes/scene-settings";

// Nested Routes
import "../scenes/scene-dashboard-critical-issues";
import "../scenes/scene-dashboard-all-alerts";
import "../scenes/scene-dashboard-patient-trends";
import "../scenes/scene-dashboard-care-metrics";
import "shared/scenes/scene-insights";
import "shared/scenes/scene-sms-hub";
import "../scenes/scene-visit-confirmation";
import "shared/scenes/scene-patient-checkpoints"; //trends
import "shared/scenes/scene-avoidable-events";
import "shared/scenes/scene-patient-alerts";
import "shared/scenes/scene-patient-availability";

import "shared/scenes/scene-patient-calendar";

import "shared/scenes/scene-patient-checkpoint-cards";
import "shared/scenes/scene-patient-checkpoint-details";
import "shared/scenes/scene-patient-files";
import "shared/scenes/scene-patient-notes";
import "shared/scenes/scene-patient-communication";
import "shared/scenes/scene-patient-activity";
import "shared/scenes/scene-patient-oasis";
import "shared/scenes/scene-patient-oasis-detail";
import "shared/scenes/scene-patient-summary";
import "shared/components/communication/app-softphone-container";
import directus from "shared/lib/lib-directus";
import ApplicationState from "applicationstate";

const routes = [
    { path: "/", redirect: "/dashboard" },
    { path: "/avoidable-events", redirect: "/avoidable-events/overview" },
    { path: "/avoidable-events/overview", component: "scene-avoidable-events" },
    { path: "/avoidable-events/metrics", component: "scene-avoidable-events" },
    { path: "/dashboard", redirect: "/dashboard/overview" },
    { path: "/dashboard/overview", component: "scene-dashboard-overview" },
    { path: "/dashboard/critical-issues", component: "scene-dashboard-critical-issues" },
    { path: "/dashboard/all-alerts", component: "scene-dashboard-all-alerts" },
    { path: "/dashboard/trends", component: "scene-dashboard-patient-trends" },
    { path: "/dashboard/care-metrics", component: "scene-dashboard-care-metrics" },
    { path: "/patients", component: "scene-patients" },
    { path: "/patients/:patient_id/checkpoints", component: "scene-patient-checkpoint-cards" },
    { path: "/patients/:patient_id/checkpoints/:survey_id", component: "scene-patient-checkpoint-details" },
    { path: "/patients/:patient_id/trends", component: "scene-patient-checkpoints" },
    { path: "/patients/:patient_id/summary/:episode_id", component: "scene-patient-summary" },
    { path: "/patients/:patient_id/alerts", component: "scene-patient-alerts" },
    { path: "/patients/:patient_id/alerts/:alert_id", component: "scene-patient-alerts" },

    { path: "/patients/:patient_id/activity", component: "scene-patient-availability" },
    { path: "/patients/:patient_id/activity/:sms_id", component: "scene-patient-availability" },

    { path: "/patients/:patient_id/client-calendar", component: "scene-patient-calendar" },
    { path: "/patients/:patient_id/client-calendar/:availability_id", component: "scene-patient-calendar" },

    { path: "/patients/:patient_id/notes", component: "scene-patient-notes" },
    { path: "/patients/:patient_id/communication", component: "scene-patient-communication" },
    // { path: "/patients/:patient_id/activity", component: "scene-patient-activity" },
    // { path: "/patients/:patient_id/oasis", component: "scene-patient-oasis" },
    // { path: "/patients/:patient_id/oasis/:oasis_id", component: "scene-patient-oasis-detail" },
    // { path: "/patients/:patient_id/files", component: "scene-patient-files" },
    { path: "/insights", component: "scene-insights" },
    { path: "/insights/checkpoints", component: "scene-insights" },
    { path: "/insights/patients", component: "scene-insights" },
    { path: "/insights/patients-plus", component: "scene-insights" },
    { path: "/insights/calls", component: "scene-insights" },
    { path: "/insights/alerts", component: "scene-insights" },

    { path: "/imports", redirect: "/imports/patients-pending" },
    { path: "/imports/patients-pending", component: "scene-import" },

    { path: "/imports/patients-declined", component: "scene-import" },
    { path: "/reports", component: "scene-reports" },
    { path: "/reports/:category", component: "scene-reports" },
    { path: "/reports/:category/:report", component: "scene-reports" },
    { path: "/visit-confirmation", component: "scene-visit-confirmation" },
    { path: "/settings", component: "scene-settings" },
    { path: "/settings/company", component: "scene-settings" },
    { path: "/settings/careteam", component: "scene-settings" },
    { path: "/settings/permissions", component: "scene-settings" },
    { path: "/settings/notifications", component: "scene-settings" },
    { path: "/settings/financial-impact", component: "scene-settings" },
    { path: "/settings/client-kpis", component: "scene-settings" },
    { path: "/settings/sms-template", component: "scene-settings" },
    { path: "/settings/schedule", component: "scene-settings" },
    { path: "/sms-hub", component: "scene-sms-hub" },

];

let sidebar_routes = [
    { href: "dashboard", label: "Dashboard", icon: "speed" },
    { href: "patients", label: "Patients", icon: "person" },
    //{ href: "visit-confirmation", label: "Visit Confirmation", icon: "free_cancellation" },
    { href: "reports", label: "Reports", icon: "trending_up" },
    //{ href: "avoidable-events", label: "Avoidable Events", icon: "attach_money" },
    { href: "insights", label: "Insights", icon: "insights" },
    { href: "sms-hub", label: "SMS HUB", icon: "sms" },
]

export default class MainApp extends HTMLElement {
    constructor() {
        super();
        this._is_loading = true;
        this._router_initialized = false;
    }

    get is_loading() {
        return this._is_loading;
    }

    set is_loading(value) {
        this._is_loading = !!value;
        this.render();
    }

    get user() {
        return this._user;
    }

    set user(value) {
        this._user = value;
        this.render();
    }

    connectedCallback() {
        this.loadingTemplate = () => html`<div class="loader"><img src="images/integration_running.gif" alt="Loading..."></div>`;
        this.loginTemplate = () => html`<app-login .appname=${"client"} @change=${(e) => this.handleLoginChange(e)}></app-login>`;
        this.passwordResetTemplate = () => html`<app-password-reset .appname=${"client"}></app-password-reset>`;
        this.noClientTemplate = () => html`<app-noclient></app-noclient>`;
        this.template = () => html`
            <app-sidebar
                .app_title=${"Visit Bridge Analytics"}
                .routes=${sidebar_routes}
                .utilities=${[
                { href: "settings", label: "Settings", icon: "settings", }
            ]}></app-sidebar>
            <main
                id="main-view"
                style="
                flex-grow: 1; 
                display: flex; 
                flex-direction: column;
                width: 100%;
                max-width: 1960px;
                margin: 0 auto;
                /*overflow-x: hidden;*/
                position: relative;
            ">
                <app-header .title=${"Visit Bridge"}></app-header>
                <article
                    class="container-fluid"
                    id="main-router-outlet"
                    style="height: calc(100vh - 50px); padding: 0;"></article>
            </main>
            <app-drawer id="drawer_end" .placement=${"end"}></app-drawer>
            <app-toaster></app-toaster>
            <app-softphone-container
                style="
                    position: absolute;
                    top: 0px;
                    height: 100px;
                    display: flex;
                    width: 100%;
                    pointer-events: none;
                "></app-softphone-container>
        `;
        Object.assign(this.style, {
            display: "flex",
            height: "100vh",
        });

        window.addEventListener('vaadin-router-location-changed', () => {
            const loader = document.getElementById("main-router-outlet");
            const current_url = new URL(window.location.href);
            if (loader && current_url.pathname.includes("overview")) {
                loader.classList.add('loading');
            } else {
                loader.classList.remove('loading');
            }
        });

        this.addEventListener("show_drawer", e => this.handleShowDrawer(e));
        this.render();
        // this.initApp();
    }

    render() {
        if (!this.template) return;
        if (this._is_loading) return render(this.loadingTemplate(), this);
        if (window.location.pathname === "/client/resetpassword") return render(this.passwordResetTemplate(), this);
        if (!this._user) return render(this.loginTemplate(), this);
        if (!this._user.client_access?.length > 1 && this._user?.role !== "Administrator") return render(this.noClientTemplate(), this);

        render(this.template(), this);
        this.initRouter(routes);
    }

    /**
     * Show a drawer component
     * @param {Event} e 
     */
    handleShowDrawer(e) {
        /** @type {import("shared/components/app-drawer").DrawerOptions} */
        let detail = e.detail;
        let placement = detail.placement;
        let drawer = this.querySelector("#drawer_" + placement);
        if (!drawer)
            console.error("Unable to open drawer: #drawer_" + placement);
        drawer.options = detail;
        drawer.show();
    }

    /**
     * Handle a login change event from <app-login> and set the user if present.
     * @param {void} e
     */
    async handleLoginChange(e) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        if (e.user) {
            this.user = e.user;
            await this.initApp();
            this.render();
        }
    }

    /**
     * This function runs just after login or just after check session passes successfully.
     */
    //  async initApp() {
    //      //await directusWebsocket.connect();
    //      const current_client_id = getCurrentClientId();
    //      if (current_client_id) {
    //          const client_settings = await ClientSettings.getSettings(current_client_id);
    //          if (client_settings?.company?.enable_roster_import_approval_workflow?.value === "true") {
    //              /** @type {import("shared/components/app-sidebar").SidebarRoute} */
    //              let approval_route = {
    //                  href: "imports",
    //                  label: "Pending Approval",
    //                  icon: "install_desktop",
    //                  notification_settings: {
    //                      state_path: 'app.pending_approval_count'
    //                  }
    //              };
    //              //trigger re-render since the array changes
    //              sidebar_routes = ute];
    //          }
    //      }

    //      //set an interval to poll for live notification badge updates
    //      setInterval(async () => {
    //          //check for patients pending approval
    //          let result = await directus.items("patient").readByQuery(
    //              {
    //                  fields: ['id'],
    //                  filter: {
    //                      client_id: getCurrentClientId(),
    //                      status: 'import_approval_requested'
    //                  },
    //                  aggregate: {
    //                      count: "id"
    //                  }
    //              }
    //          );
    //          let count = parseInt(result.data[0].count.id);
    //          ApplicationState.set("app.pending_approval_count", count, { persist: false });

    //      }, 10 * 1000 * 60)

    //  }

    async updateBadgeCount() {
        try {
            const result = await directus.items("patient").readByQuery({
                fields: ['id'],
                filter: { client_id: getCurrentClientId(), status: 'import_approval_requested' },
                aggregate: { count: "id" }
            });
            const count = parseInt(result.data[0]?.count?.id || 0, 10);
            ApplicationState.set("app.pending_approval_count", count);
        } catch (error) {
            console.error('Error updating badge count:', error);

        }
    }

    async initApp() {
        console.log('Initializing app...');
        //await directusWebsocket.connect();
        const current_client_id = getCurrentClientId();
        if (current_client_id) {
            const client_settings = await ClientSettings.getSettings(current_client_id);
            //if (client_settings?.company?.enable_roster_import_approval_workflow?.value === "true") {
            //    sidebar_routes.push({
            //        href: "imports",
            //        label: "Pending Approval",
            //        icon: "install_desktop",
            //        notification_settings: { state_path: 'app.pending_approval_count' }
            //    });
            //}

            if (client_settings?.company?.selfmanaged?.value === "true") {
                console.log('is true', client_settings?.company?.selfmanaged?.value);
                sidebar_routes.push({
                    href: "/staff/dashboard/overview",
                    label: "CareNav Dash",
                    icon: "ballot",

                });
            }
        }

        // Immediate update of count
        console.log('Updating badge count...');
        await this.updateBadgeCount();

        // updates every 10 minutes 
        console.log('Setting up badge count polling every 10 minutes...');
        setInterval(() => {
            console.log('Polling for badge count update...');
            this.updateBadgeCount();
        }, 600000);
    }




    /**
     * Start up the router once and only once
     * @returns {void}
     */
    initRouter() {
        if (this._router_initialized) return;
        requestAnimationFrame(() => {
            const element = document.getElementById("main-router-outlet");
            router.init(element, routes);
            this._router_initialized = true;
            document.getElementById("main-view").addEventListener("click", () => {
                this.handleMainViewClick();
            });
        });
    }

    /**
     * Any action that should be taken if anywhere in the main view is clicked
     * Currently does the following:
     * - Triggers a sidebar close event
     */
    handleMainViewClick() {
        const close_sidebar_event = new CustomEvent("sidebar:close");
        document.dispatchEvent(close_sidebar_event);
    }
}

customElements.define("main-app", MainApp);