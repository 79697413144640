import { html, render } from "lit";
import ModalBase from './util/modal-base';

export default class AppDeactivatePatientModal extends ModalBase {
    constructor() {
        super();
        this.reason = null;
        this.notes = null;
        this.keepManualItems = false;
        this.schedule = null;
        this.schedules = null;
        this.affectedCounts = null;
        this.schedule_modal = '';
    }

    connectedCallback() {
        this.template = () => {
            const isBulk = this.schedules && this.schedules.length > 0;
            const title = isBulk
                ? `Deactivate ${this.schedules.length} Schedules`
                : 'Deactivate Schedule';

            return html`
                <style>
                    #deactivate-patient-modal .modal-cancel-btn {
                        background-color: var(--t-color-grey);
                        border-color: var(--t-color-grey);
                    }
                    #deactivate-patient-modal .modal-cancel-btn:hover {
                        background-color: var(--t-color-grey-darkened);
                        border-color: var(--t-color-grey-darkened);
                    }
                    #deactivate-patient-modal .modal-confirm-btn {
                        background-color: var(--t-color-danger);
                        border-color: var(--t-color-danger);
                    }
                    #deactivate-patient-modal .modal-confirm-btn:hover {
                        background-color: var(--t-color-danger-darkened);
                        border-color: var(--t-color-danger-darkened);
                    }
                    #deactivate-patient-modal select {
                        cursor: pointer;
                    }
                    #deactivate-patient-modal .input-group:last-of-type {
                        margin-top: 12px;
                    }
                    .affected-items {
                        margin-top: 8px;
                        padding: 8px;
                        background-color: #f8f9fa;
                        border-radius: 4px;
                        font-size: 0.9em;
                    }
                    .affected-items strong {
                        color: var(--t-color-danger);
                    }
                    .keep-manual-items {
                        margin-top: 16px;
                    }
                </style>
                <div class="modal-dialog modal-dialog-centered" id="deactivate-patient-modal">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">${title}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form id="deactivate_patient_form" novalidate>
                            <div class="modal-body">
                                <div class="affected-items">
                                    <div class="alert alert-danger" role="alert">
                                      This will remove all selected tasks and communications from the patient's schedule.
                                    </div>
                                    
                                </div>

                                <div id="reason-select-wrapper" class="mb-3">
                                    <label for="reason_select" class="form-label">Reason</label>
                                    <select @change=${e => this.handleReasonChange(e)} id="reason_select" class="form-select" aria-label="Reason" required>
                                        <option value="" disabled selected hidden>Select a reason</option>
                                        <option value="successfully_completed">Successfully Completed</option>
                                        <option value="patient_opt_out">Patient Opt Out</option>
                                        <option value="provider_opt_out">Provider Opt Out</option>
                                        <option value="hospitalized">Hospitalized</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        Please select a deactivation reason.
                                    </div>
                                </div>

                                <div class="input-wrapper">
                                    <label for="notes_textarea" class="form-label">Notes</label>
                                    <textarea @change=${e => this.handleNotesChange(e)} id="notes_textarea" class="form-control" aria-label="Notes"></textarea>
                                </div>


                                <!--
                                <div class="keep-manual-items form-check">
                                    <input 
                                        class="form-check-input" 
                                        type="checkbox" 
                                        id="keep_manual_items"
                                        @change=${e => this.keepManualItems = e.target.checked}
                                    >
                                    <label class="form-check-label" for="keep_manual_items">
                                        Keep manually added items
                                    </label>
                                </div>
                            </div>

                -->

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary modal-cancel-btn light-gray-btn" data-bs-dismiss="modal" @click=${(_e) => this.handleCancel()}>
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary dark-red-btn"
                                    id="confirm-button"
                                    @click=${(_e) => this.handleConfirmClick()}>
                                    Confirm
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    async handleCancel() {
        if (this.schedule_modal) {
            this.schedule_modal.classList.remove("inactive");
        }

    }

    async handleConfirmClick() {
        if (this.schedule_modal) {
            this.schedule_modal.classList.remove("inactive");
        }
        const form_element = this.querySelector('#deactivate_patient_form');
        form_element.classList.add('was-validated');
        const valid = form_element.checkValidity();
        if (valid) {
            this.dismiss({
                confirmed: true,
                reason: this.reason,
                notes: this.notes,
                keepManualItems: this.keepManualItems
            });
        }
    }

    handleNotesChange(e) {
        this.notes = e.target.value;
    }

    handleReasonChange(e) {
        this.reason = e.target.value;
        this.render();
    }
}

customElements.define("app-deactivate-patient-modal", AppDeactivatePatientModal);
