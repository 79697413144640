import { html, render } from "lit";
import directus from '../lib/lib-directus';
import "../components/patient/app-patient-card";
import "../components/patient/app-patient-filters";
//import AppPatientManualEntryModal from "../components/patient/app-patient-manual-entry-modal";
import AppPatientCareTeamModal from "../components/patient/app-patient-care-team-modal";
import "../components/app-toggle-switch";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"
import { navigate } from "../lib/lib-router";
import { adddirectusClientFilter } from "../lib/lib-user";
import ApplicationState from 'applicationstate';
import { format, parseISO } from 'date-fns';
import AppCalendarViewModal from "../components/availability/app-calendar-view-modal";
import AppActivateScheduleModal from "../components/app-activate-schedule-modal";
import AppWarningModal from "../components/app-warning-modal";
import { getCurrentClient, getCurrentClientId } from "../lib/lib-user";
import AppOvalModal from "../components/app-oval-modal";
import ClientSettings from "../lib/lib-client-settings";
import { cs } from "date-fns/locale";

import AppConfirmationModal from "../components/app-confirmation-modal";
import AppPatientSchedulesModal from "../components/patient/app-patient-schedules-modal";

window.luxon = { DateTime };

const card_height = 112;
const patient_count_height = 30;

// TODO: exported?

const sort_label_to_column = {
    "LAST SURVEY DATE": "last_survey_date",
    "BIRTH DATE": "birth_date",
    "FIRST NAME": "first_name",
    "LAST NAME": "last_name",
    "MRN": "mrn",
    "SOC DATE": "current_episode_start",
    "STATUS": "status",
};

/**
 * A grid of patient cards, supports search, sort, and filter.
 */
export default class ScenePatients extends HTMLElement {

    get patient() {
        return this._patient;
    }

    set patient(value) {
        this._patient = value;
        this.expandPatient();
        this.render();
    }

    get has_changes() {
        return this._has_changes;
    }

    set has_changes(value) {
        this._has_changes = !!value;
        this.render();
    }

    get editable() {
        return this._editable;
    }

    set editable(value) {
        this._editable = !!value;
        this.render();
    }

    get clinicians() {
        return this._clinicians;
    }

    set clinicians(values) {
        this._clinicians = values;
    }

    get case_managers() {
        return this._case_managers;
    }

    set case_managers(values) {
        this._case_managers = values;
    }

    get care_team() {
        return this._care_team;
    }

    set care_team(values) {
        this._care_team = values;
        this.render();
    }

    set view(value) {
        if (value !== this._view) {
            this._view = value;
            this.render(); // Re-render when view changes
        }
    }

    // Optional getter
    get view() {
        return this._view;
    }

    constructor() {
        super();
        this._has_changes = false;
        this._editable = false;
        this._clinicians = null;
        this._care_team = null;
        this.changes = {};
        this.patient_access_changes = {};
        // filters
        this._view = 'list';
        this._search_term = null;
        this._onlyActiveEnabled = true;
        this._noScheduleActive = false;
        // This should be an array of objects with {column: string, direction: 'asc'|'desc'}
        this._sort_fields = [{ column: "current_episode_end", direction: "desc" }];

        // pagination
        this._current_page = 1;
        //this._filtered_count = 0;
        this._page_size = null;
        // TODO: loading state during initial render / fetch
        this._loading_patients = true;
        this.patients = [];

        this._appName = ApplicationState.get('app.patient_scene');
        this.boundResizeHandler = this.handleWindowResize.bind(this);
        this._hasRecentCommunication = false; // New property to track the filter state
        this.filters = {};
        this._isActive = true;  // Default to active episodes
        this._isExpired = false;
        this._isAll = false;
    }

    /**
     * Queries the server for patients based on the current filters.
     */
    formatDate(dateString) {
        if (!dateString) return 'N/A';
        return format(new Date(dateString), 'MM/dd/yyyy');
    }

    async fetchPatients() {
        if (!this._page_size) {
            return;
        }
        this._loading_patients = true;
        const query_filters = {
            fields: [
                "id", "last_communication_date", "last_survey_date", "first_name", "last_name", "mrn", "birth_date", "status", "current_episode_start", "current_episode_end", "current_soc", "client_id",
                "patient_schedules.id", "patient_schedules.survey_schedule_id", "patient_schedules.survey_schedule_id.name", "patient_schedules.calculated_start_date", "patient_schedules.calculated_end_date",
                "patient_schedules.default_timezone", "patient_schedules.episode_id", "patient_schedules.type", "patient_schedules.tags",
                //"access.user_id.first_name", "access.user_id.last_name",
                "current_episode_id.id", "current_episode_id.ext_referral",
                "current_episode_id.diagnosis_description", "current_episode_id.diagnosis_icd10_code",
                "visits.id", "visits.visit_date", "visits.visit_type", "visits.visit_status", "visits.visit_description",
                //"current_episode_schedule", "current_patient_schedule", "current_adhoc_schedule",
                //"tasks.scheduled_date", "tasks.total_attempts, tasks.title, tasks.status",
                //"patient_communication.date_created",
                //"patient_communication.patient_id",
            ],
            sort: this._sort_fields.map(field => `${field.direction === 'desc' ? '-' : ''}${field.column}`).join(','),
            meta: "*",
            limit: this._page_size,
            page: this._current_page,
            filter: adddirectusClientFilter({})
        };

        const currentDate = new Date().toISOString().split('T')[0];
        console.log('Query Debug:', {
            currentDate,
            filter: query_filters.filter,
            sql: `
            -- Active Query
            select count(*) from patient 
            where client_id = 'residence-home-care' 
            and current_episode_end >= '${currentDate}';

            -- Expired Query
            select count(*) from patient 
            where client_id = 'residence-home-care' 
            and current_episode_end < '${currentDate}';
            `
        });

        // Simple episode status filter
        if (this._isActive) {
            query_filters.filter.current_episode_end = {
                _gte: currentDate
            };
        } else if (this._isExpired) {
            query_filters.filter.current_episode_end = {
                _lt: currentDate
            };
        }

        if (this._search_term) {
            query_filters.search = this._search_term;
        }

        console.log('Final Query Filters:', JSON.stringify(query_filters.filter, null, 2));

        try {
            const patients_result = await directus.items("patient").readByQuery(query_filters);
            console.log('Query Results:', {
                count: patients_result.meta.filter_count,
                firstFew: patients_result.data.slice(0, 3)
            });

            // Post-process the results to filter based on communication date
            if (this._hasRecentCommunication) {
                patients_result.data = patients_result.data.filter(patient => {
                    if (patient.current_episode_start && patient.patient_communication && patient.patient_communication.length > 0) {
                        const episodeStart = new Date(patient.current_episode_start);
                        return patient.patient_communication.some(comm => new Date(comm.date_created) > episodeStart);
                    }
                    return false;
                });
            }

            this.patients = this._current_page === 1 ? patients_result.data : this.patients.concat(patients_result.data);
            if (this._current_page === 1) {
                document.querySelector("#patients-scroll-container").scrollTop = 0;
            }
            this._filtered_count = patients_result.meta.filter_count;
            this._loading_patients = false;
            this._query = query_filters;

            if (this._view == "list") {
                this.initTable();
            }

            this.render();
        } catch (error) {
            console.error("Error fetching patients:", error);
            this._loading_patients = false;
        }
    }
    /**
        * Render the patient count across all pages.
        * @returns lit html
        */
    renderPatientsCount() {
        const { _filtered_count } = this;

    }


    connectedCallback() {
        this.addEventListener('filterchange', (e) => {
            this.filters = e.detail.filters;
            this._current_page = 1;
            this.fetchPatients();
        });

        this.addEventListener('scheduletoggle', (e) => {
            this._onlyActiveEnabled = e.detail.hasSchedule;
            this._noScheduleActive = e.detail.noSchedule;
            this._current_page = 1;
            this.fetchPatients();
        });

        this.addEventListener('episodestatustoggle', (e) => {
            console.log('Episode Status Toggle Event:', e.detail);
            this._isActive = e.detail.isActive;
            this._isExpired = e.detail.isExpired;
            this._current_page = 1;
            this.fetchPatients();
        });

        // Add cleartable event listener
        this.addEventListener('cleartable', (e) => this.handleClear(e));

        this.addEventListener("viewchange", (e) => {
            this.view = e.detail.view;
            if (this.view == "list") {
                this.initTable();
            };
            console.log("viewchange listener", this.view);
        });
        this.addEventListener("datefilterchange", (e) => this.handleDateFilterChange(e));
        const last_date = this.patients.last_survey_date;
        console.log("CC View", this.view);
        this.template = () => {
            let content;
            switch (this.view) {
                case "card":
                    content = this.renderCardView();
                    //this.fetchPatients();
                    break;
                case "list":
                default:
                    //this.fetchPatients();
                    content = this.renderListView();

                    //this.initTable();
                    //this.fetchPatients();
                    break;
            }

            const initial_nav_height = document.body.clientWidth < 768 ? 133 : 188;
            const nav_height = document.querySelector("scene-patients nav")?.clientHeight || initial_nav_height;
            //console.log("nav height", nav_height);


            return html`

        <style>
            #patients-scroll-container {
                max-height: calc(100% - ${nav_height + patient_count_height}px);
                overflow-y: auto;
            }
            div.tooltip.task-tooltip {
                --bs-tooltip-bg: #fffcfc !important;
                --bs-tooltip-padding-x: 10px !important;
                --bs-tooltip-padding-y: 5px !important;
                --bs-tooltip-color: #696969 !important;
                font-size: 10px !important;
                /*--bs-tooltip-border-radius: 8px;*/
                filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.2)) !important;
            }
            div.tooltip.task-tooltip .tooltip-arrow {
                border-top-color: #fffcfc !important;
            }
            div.tooltip.task-tooltip .tooltip-inner {
                background-color: #fffcfc !important;
                color: #696969 !important;
            }

            .oasis-visit {
                font-size: 0.9em;
                line-height: 1.2;
            }
            
            .oasis-visit .visit-desc {
                color: #666;
                font-size: 0.9em;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
           scene-patients .tabulator-row .tabulator-cell.tabulator-frozen.tabulator-frozen-left {
                border-right: 2px solid #cdcdcd;
            }

            scene-patients .tabulator-row .tabulator-cell.tabulator-frozen {
                /* background: #ffffff; */
                box-shadow: inset 0px 0px 150px 0px rgb(240 245 255 / 62%);
            }

        </style>

        <div class="container-fluid" style="">
            <div class="row" style="padding: 0 32px;">
                <app-patient-filters
                
                    .count=${this._filtered_count}
                    .sort_fields=${this._sort_fields}
                    .active=${this._onlyActiveEnabled}
                    .hasRecentCommunication=${this._hasRecentCommunication}
                    .view=${this.view}
                    @patientsearch=${(e) => this.handleSearch(e)}
                    @activetoggle=${(e) => this.handleActiveToggle(e)}
                    @recentcommunicationtoggle=${(e) => this.handleRecentCommunicationToggle(e)}
                    @tagifyclick=${(e) => this.handleTagifyClick(e)}
                    @tagifyadd=${(e) => this.handleSortColumnAdd(e)}
                    @tagifyremove=${(e) => this.handleSortColumnRemove(e)}
                ></app-patient-filters>
                
            </div>
            ${this.renderPatientsCount()}
               
                 ${content}
                             
        </div>
    `;


        };

        Object.assign(this.style, {
            height: "100%",
            display: "block",
        });

        this.render();

        // Apply initial filters
        this.filters = {
            start_date_range: this.defaultFilterDates()
        };
        this.fetchPatients();
        this.init();
        // Schedule updatePageSize for the next frame to ensure DOM is ready
        requestAnimationFrame(() => this.updatePageSize());
        window.addEventListener("resize", this.boundResizeHandler);
    }

    renderCardView() {

        return html`
        <div class="row g-0 px-3 pt-2" id="patients-scroll-container" @scroll=${(e) => this.handleScroll(e)}>
        <div class="row g-0" id="patients-cards">
            ${this.patients.map(patient => html`
                <div class="col-sm-12 col-md-6 col-lg-4 p-card-cont">
                    <app-patient-card
                        @click=${() => navigate(`patients/${patient.id}/checkpoints`)}
                        .patient=${patient}
                        style="cursor: pointer;">
                    </app-patient-card>
                </div>
            `)}
        </div>
        </div>
    `;
    }

    renderListView() {
        return html`
             <div class="row px-3 pt-2" id="patients-scroll-container" style="overflow:hidden;">
            <div class="mod-base" id="patients-table" ></div> 
            </div>
            `

    }
    async getAuthToken() {
        if (!this.cachedToken) {
            this.cachedToken = await directus.auth.token; // retrieve token
        }
        return this.cachedToken;
    }

    async initTable() {
        const table_columns = [
            {
                hozAlign: "left",
                cssClass: "patient-actions",
                title: `
                    <div class="patient-actions-container">
                        <span class="material-symbols-outlined"
                              data-bs-tooltip="tooltip"
                              data-bs-custom-class="task-tooltip"
                              data-bs-placement="bottom"
                              title="View Patient Details">group_add</span>
                              
                        <span class="material-symbols-outlined"
                              data-bs-tooltip="tooltip"
                              data-bs-custom-class="task-tooltip"
                              data-bs-placement="bottom" 
                              title="View Calendar">calendar_month</span>
                    
                        <span class="material-symbols-outlined"
                              data-bs-tooltip="tooltip"
                              data-bs-custom-class="task-tooltip"
                              data-bs-placement="bottom" 
                              title="Apply Schedule">calendar_add_on</span>

                        <span class="material-symbols-outlined"
                              data-bs-tooltip="tooltip"
                              data-bs-custom-class="task-tooltip"
                              data-bs-placement="bottom"
                              title="Manage Schedule">edit_calendar</span>

  
                    </div>
                `,
                formatter: function (cell) {
                    const rowData = cell.getRow().getData();

                    const hasESchedule = rowData.current_episode_id.survey_schedule_id;
                    const hasPSchedule = rowData.patient_schedules?.some(schedule =>
                        schedule.type === 'patient' && schedule.status === 'active'
                    );

                    return `
                       
                    <div class="patient-actions-container">
                        <span class="material-symbols-outlined">group_add</span>
                        <span class="material-symbols-outlined">calendar_month</span>
                        <span class="material-symbols-outlined">calendar_add_on</span>
                        <span class="material-symbols-outlined ${hasESchedule ? 'has-episode-schedule' : ''} ${hasPSchedule ? 'has-patient-schedule' : ''}">edit_calendar</span>
                       
                    </div>
                    `;
                },
                cellClick: (e, cell) => {
                    const patientData = cell.getRow().getData();
                    if (e.target.textContent.trim() === 'group_add') {
                        this.handleEditCareteam(patientData.id);
                    } else if (e.target.textContent.trim() === 'calendar_month') {
                        this.showCalendar(patientData);
                    } else if (e.target.textContent.trim() === 'calendar_add_on') {
                        this.handleSingleActivate(patientData);
                    } else if (e.target.textContent.trim() === 'edit_calendar') {
                        this.showSchedules(patientData);
                    }
                },
                width: 150,
                hozAlign: "center",
                frozen: true,
                tooltip: false,
                headerSort: false,
            },
            {
                title: "Name",
                formatter: function (cell) {
                    const data = cell.getRow().getData();
                    return `<strong>${data.first_name} ${data.last_name}</strong> - ${data.mrn}`;
                },
                cellClick: function (e, cell) {
                    navigate(`patients/${cell.getRow().getData().id}/activity`);
                },
                sorter: "string",
                sorterParams: {
                    field: "last_name",
                },
                width: "15%",
                frozen: true,
                tooltip: false
            },
            {
                title: "Episode",
                field: "current_episode_end",
                sorter: "datetime",
                hozAlign: "center",
                width: 200,
                sorterParams: {
                    format: "yyyy-MM-dd",
                    alignEmptyValues: "bottom",
                },
                formatter: function (cell, formatterParams) {
                    try {
                        const rowData = cell.getRow().getData();
                        const start = format(parseISO(rowData.current_episode_start), "M/d/yy");
                        const end = format(parseISO(rowData.current_episode_end), "M/d/yy");
                        return start + " - " + end;
                    } catch (error) {
                        return ""
                    }
                }
            },
            {
                title: "Primary DX",
                field: "current_episode_id.diagnosis_description",
                width: "25%",
                widthGrow: 2,
            },
            {
                title: "ICD10",
                field: "current_episode_id.diagnosis_icd10_code",
                width: "10%",
            },
            {
                title: "Ext Refferal",
                field: "current_episode_id.ext_referral",
                widthGrow: 1,
            },
            {
                title: "Schedule",
                field: "patient_schedules",
                width: "15%",
                formatter: function (cell, formatterParams) {
                    const rowData = cell.getRow().getData();
                    const schedules = rowData.patient_schedules;

                    if (schedules && schedules.length > 0 && schedules[0].survey_schedule_id) {
                        return `<div class="has-schedule"><span id="yess"><strong>${schedules[0].survey_schedule_id.name}</strong></span></div>`;
                    } else {
                        return '<div class="has-schedule"><span id="no">No Schedule Found</span></div>';
                    }
                }
            },
            {
                title: "Latest OASIS-E",
                field: "visits",
                width: "15%",
                formatter: function (cell) {
                    const visits = cell.getValue();
                    if (!visits || !visits.length) return "No visits";

                    // Filter for OASIS-E visits and sort by date descending
                    const oasisVisits = visits
                        .filter(visit => visit.visit_description?.includes('OASIS-E'))
                        .sort((a, b) => new Date(b.visit_date) - new Date(a.visit_date));

                    if (!oasisVisits.length) return "No OASIS-E visits";

                    const latestVisit = oasisVisits[0];
                    return `<div class="oasis-visit">
                        <div>${format(parseISO(latestVisit.visit_date), "MM/dd/yyyy")}</div>
                        <div class="visit-desc">${latestVisit.visit_description}</div>
                    </div>`;
                },
                sorter: function (a, b, aRow, bRow) {
                    const getLatestOasisDate = (visits) => {
                        if (!visits || !visits.length) return new Date(0);
                        const oasisVisits = visits.filter(v => v.visit_description?.includes('OASIS-E'));
                        if (!oasisVisits.length) return new Date(0);
                        return new Date(oasisVisits[0].visit_date);
                    };

                    const aDate = getLatestOasisDate(a);
                    const bDate = getLatestOasisDate(b);
                    return aDate - bDate;
                }
            },
        ];

        const token = await this.getAuthToken();
        const query = this._query;

        this.data_table = new Tabulator(this.querySelector("#patients-table"), {
            ajaxURL: "/items/patient",
            ajaxConfig: { headers: { 'Authorization': `Bearer ${token}` } },
            ajaxFiltering: true,
            ajaxSorting: true,
            progressiveLoad: "scroll",
            progressiveLoadScrollMargin: 200,
            dataSendParams: { "size": "limit" },
            height: "auto",
            width: "100%",
            paginationCounter: "rows",
            alignEmptyValues: "bottom",
            layout: "fitData",
            layoutColumnsOnNewData: true,
            sortMode: "remote",
            debugInvalidOptions: false,
            persistence: true,
            persistenceID: "import_patients_soc_table",
            persistenceMode: "local",
            persistentLayout: true,
            persistentSort: false,
            persistentFilter: false,
            filterMode: "remote",
            movableColumns: true,
            columnMoved: this.saveColumnOrder.bind(this),
            filterMode: "remote",
            rowFormatter: function (row) {
                const tooltipTriggerList = document.querySelectorAll('[data-bs-tooltip="tooltip"]');
                tooltipTriggerList.forEach(tooltipTriggerEl => {
                    new bootstrap.Tooltip(tooltipTriggerEl, {
                        placement: 'top',
                        trigger: 'hover',
                        delay: { show: 100, hide: 50 },
                        container: 'body',
                        // customClass: 'action-icons-tooltip',
                        fallbackPlacement: 'none',
                        boundary: 'viewport',
                        popperConfig: {
                            modifiers: [
                                {
                                    name: 'flip',
                                    options: {
                                        fallbackPlacements: []
                                    }
                                },
                                {
                                    name: 'arrow',
                                    options: {
                                        element: '.tooltip-arrow',
                                    },
                                },
                            ]
                        }
                    });
                });
            },

            ajaxURLGenerator: function (url, config, params) {
                let queryParams = [];

                if (query?.fields?.length) {
                    queryParams.push(`fields[]=${query.fields.join('&fields[]=')}`);
                }

                let sortParams = [];
                if (query?.sort) {
                    sortParams.push(query.sort);
                }
                if (params?.sort && params.sort.length) {
                    params.sort.forEach(sorter => {
                        if (sorter.field) {
                            let sortField = sorter.field;
                            if (sortField === 'name') {
                                sortField = 'last_name';
                            }
                            sortParams.push(`${sorter.dir === 'desc' ? '-' : ''}${sortField}`);
                        }
                    });
                }
                if (sortParams.length) {
                    queryParams.push(`sort=${sortParams.join(',')}`);
                }

                if (query?.filter) {
                    const addFilterParam = (prefix, obj) => {
                        Object.entries(obj).forEach(([key, value]) => {
                            if (typeof value === 'object' && value !== null) {
                                addFilterParam(`${prefix}[${key}]`, value);
                            } else {
                                queryParams.push(`${prefix}[${key}]=${encodeURIComponent(value)}`);
                            }
                        });
                    };
                    addFilterParam('filter', query.filter);
                }
                if (params?.filter && params.filter.length) {
                    params.filter.forEach(filter => {
                        queryParams.push(`filter[${filter.field}]=${filter.value}`);
                    });
                }

                if (query?.search) {
                    queryParams.push(`search=${encodeURIComponent(query.search)}`);
                }

                queryParams.push(`page=${params?.page || 1}`);
                queryParams.push(`limit=${params?.pageSize || 10}`);
                queryParams.push('meta=filter_count');

                let finalURL = `${url}?${queryParams.join('&')}`;
                return finalURL;
            },

            ajaxResponse: function (url, params, response) {
                const last_page = Math.ceil(response.meta.filter_count / params.limit);
                return {
                    data: response.data,
                    last_page,
                };
            }.bind(this),

            columns: table_columns
        });
        this.setTableHeight();
    }

    saveColumnOrder() {
        const columnOrder = this.data_table.getColumns().map(column => column.getField());
        ApplicationState.set('app.patient_scene.column_order', columnOrder, { immutable: true });
    }

    applyColumnOrder() {
        const savedOrder = ApplicationState.get('app.patient_scene.column_order');
        if (savedOrder && this.data_table) {
            // Temporarily remove the columnMoved event handler
            const originalHandler = this.data_table.options.columnMoved;
            this.data_table.options.columnMoved = null;

            // Apply the saved order (not reset)
            this.data_table.setColumnOrder(savedOrder);

            // Restore the columnMoved event handler
            setTimeout(() => {
                this.data_table.options.columnMoved = originalHandler;
            }, 100);
        }
    }

    handleClear(e) {
        console.log('Clearing all table persistence...');

        // Clear ApplicationState
        ApplicationState.set('app.patient_scene.column_order', null, { immutable: true });

        // Clear ALL tabulator persistence from localStorage
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith('tabulator-')) {
                console.log('Removing:', key);
                localStorage.removeItem(key);
            }
        });

        // Force page reload to start fresh
        window.location.reload();
    }


    disconnectedCallback() {
        window.removeEventListener("resize", this.boundResizeHandler);
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
        this.patients.forEach(patient => {

        });
        //this.initTable();
    }

    /**
     * Updates this.page_size based on window dimensions to support infinite scroll
     */
    updatePageSize() {
        const sceneElement = this.querySelector(".container-fluid");
        const navElement = this.querySelector("nav");

        if (!sceneElement || !navElement) {
            // Elements not ready yet, schedule an update for the next frame
            requestAnimationFrame(() => this.updatePageSize());
            return;
        }

        const scene_height = sceneElement.clientHeight;
        const nav_height = navElement.clientHeight;

        let column_count = 3;
        if (document.body.clientWidth < 768) {
            column_count = 1;
        } else if (document.body.clientWidth < 992) {
            column_count = 2;
        }

        const scroll_container_max_height = scene_height - patient_count_height - nav_height;
        this._column_count = column_count;
        this._page_size = Math.ceil((scroll_container_max_height * column_count) / card_height) + 1;
        this._current_page = 1;
        this.fetchPatients();
    }
    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 20);
    }
    async handleConfirmClick() {
        try {
            if (Object.keys(this.patient_access_changes).length > 0) {
                const to_remove = [];
                const to_add = [];
                for (const id in this.patient_access_changes) {
                    const action = this.patient_access_changes[id];

                    switch (action) {
                        case "remove":
                            to_remove.push(id);
                            break;
                        case "add":
                            const access_record = this.patient_access.find((i) => i.user_id === id);
                            // It already exists, and we've staged and unstaged a remove
                            if (access_record) break;
                            to_add.push({
                                patient_id: this.patient.id,
                                user_id: id,
                            });
                            break;
                    }
                }

                if (to_remove.length) {
                    await directus.items("patient_access").deleteMany(to_remove);
                    for (const id of to_remove) {
                        const pa = this.patient_access.find((i) => i.id === id);
                        if (
                            this.patient.case_manager_id === pa?.user_id?.id ||
                            this.patient.case_manager_id?.id === pa?.user_id?.id
                        ) {
                            this.changes.case_manager_id = null;
                        }
                        if (
                            this.patient.primary_clinician_id === pa?.user_id.id ||
                            this.patient.primary_clinician_id?.id === pa?.user_id.id
                        ) {
                            this.changes.primary_clinician_id = null;
                        }
                    }
                }

                if (to_add.length)
                    await directus.items("patient_access").createMany(to_add);
            }

            // If we have any patient changes, save those.
            if (Object.keys(this.changes).length > 0) {
                if (this.changes?.primary_clinician_id?.id)
                    this.changes.primary_clinician_id = this.changes.primary_clinician_id.id;
                if (this.changes?.case_manager_id?.id)
                    this.changes.case_manager_id = this.changes.case_manager_id.id;

                const res = await directus.items("patient").updateOne(this.patient.id, this.changes, {
                    fields: [
                        '*',
                        'current_episode_id.*',
                        'client_id.*',
                    ]
                });

                this.changes = {};
            }
        } catch (err) {
            console.error(err);
            this.error_message = err.message;
            return;
        }

        // reload the patient to make sure that all
        this.patient = await Cache.getPatient(this.patient.id, true);
        this.dismiss({
            should_update: this.editable,
            patient: this.patient,
        });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////


    async showSchedules(patient) {

        const modal = new AppPatientSchedulesModal();
        modal.patient_id = patient.id;
        modal.patient_name = `${patient.first_name} ${patient.last_name}`;
        // Add episode context to filter schedules
        modal.episode_id = this.selected_episode_id; // You'll need to track this
        await modal.showModal();
    }

    async handleSingleActivate(patient) {
        //const patient = {
        //    id: id,
        //    first_name: first_name,
        //    last_name: last_name,
        //    client_id: client_id,
        //    episode_id: current_episode_id.id
        //};

        const activation_modal = new AppActivateScheduleModal();
        activation_modal.patients = [patient];
        activation_modal.default_sms_time = this.default_sms_time;
        activation_modal.default_timezone = this.default_timezone;
        await activation_modal.showModal();
        const modal_result = await activation_modal.onDidDismiss();

        if (modal_result?.confirmed) {
            let message = modal_result.message;
            this.showConfirmationModal(message);
            this.refreshTableData();
        }
    }

    async handleEditCareteam(patientId) {
        console.log("handleEditCareteam", patientId);

        // Fetch the full patient object based on patientId
        const patientData = await directus.items("patient").readOne(patientId);

        const modal = new AppPatientCareTeamModal();
        modal.patient = patientData;
        await modal.showModal();
        await modal.onDidDismiss();
        //this.patient = patient;
    }

    async handleCareTeamAdd() {
        const id_to_add = this.querySelector("#potential-care-team-member").value;
        if (!id_to_add) return;

        this.care_team.push(this.clinicians.find((i) => i.id === id_to_add));
        this.patient_access_changes[id_to_add] = "add";
        this.hasChanges();
    }

    /**
     * Removes the care team member
     * @param member
     * @returns {Promise<void>}
     */
    async handleRemoveCareTeamMember(e, member) {
        e.stopPropagation();
        const to_remove = this.patient_access.find((i) => member.id === i.user_id?.id);
        if (!to_remove) return;
        this.patient_access_changes[to_remove.id] = "remove";
        this.care_team = this.care_team.filter((i) => i.id !== to_remove.user_id?.id);
        this.hasChanges();
    }


    /**
     * Updates only active toggle and queries the server.
     * @param {Event} e change event from 'only active patients' checkbox
     */
    handleActiveToggle(e) {

        if (this._onlyActiveEnabled !== undefined) {
            this._onlyActiveEnabled = e.detail;
        }


        this._currentPage = 1;
        this.fetchPatients();
    }

    async handlePatientAddClick(e) {
        e.stopPropagation();
        e.preventDefault();
        // load patient-add modal
        const appPatientManualEntryModal = new AppPatientManualEntryModal();
        await appPatientManualEntryModal.showModal();
    }

    async showCalendar(patient) {
        const modal = new AppCalendarViewModal(patient);
        await modal.showModal();
    }

    /**
     * If the number of columns changes based on window width,
     * recalculate page size for infinite scroll and refetch patients.
     * @todo handle height changes
     * @param {Event} e window resize event
     */
    handleWindowResize(_e) {
        const { clientWidth } = document.body;
        if (
            (this._column_count === 1 && clientWidth >= 768) ||
            (this._column_count === 2 && clientWidth >= 992) ||
            (this._column_count === 2 && clientWidth < 768) ||
            (this._column_count === 3 && clientWidth < 992)
        ) {
            this.updatePageSize();
        }
    }

    /**
     * Enables infinite scroll / pagination on the scroll container.
     * @param {Event} e scroll event
     */
    handleScroll(e) {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        const all_pages_fetched = this.patients.length === this._filtered_count;
        if (!all_pages_fetched && !this._loading_patients && scrollHeight - clientHeight - scrollTop < 300) {
            this._current_page++;
            this.fetchPatients();
        }
    }

    /**
     * Debounces a patient search query.
     * @param {Event} e input event from search box
     */
    handleSearch(e) {
        e.stopPropagation();
        const search_term = e.detail.target.value;
        if (search_term && search_term.length < 3) return;
        clearTimeout(this._debounce);
        this._debounce = setTimeout(() => {
            this._search_term = search_term;
            this._current_page = 1;
            this.fetchPatients();
            //this.initTable();
        }, 500);
    }

    /**
     * Appends a sort column with default direction asc and re-fetches.
     * @param {Event} e tagify 'add' event
     */
    handleSortColumnAdd(e) {
        const { data: tag_data } = e.detail;

        this._sort_fields.push({
            column: sort_label_to_column[tag_data.value],
            direction: "asc",
        });
        this._current_page = 1;
        this.fetchPatients();
    }

    /**
     * Removes the selected sort column and re-fetches.
     * @param {Event} e tagify 'remove' event
     */
    handleSortColumnRemove(e) {
        const { data: tag_data } = e.detail;
        const index_to_remove = this._sort_fields.findIndex(
            (field) => field.column === sort_label_to_column[tag_data.value]
        );
        this._sort_fields.splice(index_to_remove, 1);
        this._current_page = 1;
        this.fetchPatients();
    }

    /**
     * On clicking a sort tag/chip, reverse the sort direction for the given field and refetch patients.
     * @param {CustomEvent} e tagify 'click' event from <app-patient-filters>
     */
    handleTagifyClick(e) {
        const { data: tag_data } = e.detail;
        e.stopPropagation();
        // change sort direction
        const sort_field = this._sort_fields.find((field) => field.column === sort_label_to_column[tag_data.value]);
        sort_field.direction = sort_field.direction === "asc" ? "desc" : "asc";
        this._current_page = 1;
        this.fetchPatients();
    }

    // Add a new method to handle the toggle
    handleRecentCommunicationToggle(e) {
        this._hasRecentCommunication = e.detail;
        this._current_page = 1;
        this.fetchPatients();
    }

    handleDateFilterChange(e) {
        const { start, end } = e.detail;
        this.filters = this.filters || {};
        // Ensure we're using the correct date format (YYYY-MM-DD)
        this.filters.start_date_range = `${start.toISOString().split('T')[0]},${end.toISOString().split('T')[0]}`;
        this._current_page = 1;
        this.fetchPatients();
    }

    // Add this method to match the one in app-patient-filters.js
    defaultFilterDates() {
        const curDate = new Date();
        const thirtyDaysAgo = new Date(curDate);
        thirtyDaysAgo.setDate(curDate.getDate() - 30);

        const fromDate = thirtyDaysAgo.toISOString().split('T')[0];
        const toDate = curDate.toISOString().split('T')[0];

        return `${fromDate},${toDate}`;
    }


    /**
    * Warns the user if incorrect patient selections are made for an action.
    * @param {String} modal_text 
    */
    showWarningModal(modal_text) {
        const warning_modal = new AppWarningModal();
        warning_modal.modal_text = modal_text;
        warning_modal.showModal();

        this.refreshTableData();
    }

    async init() {
        const current_client_id = getCurrentClientId();

        if (current_client_id) {
            const client_settings = await ClientSettings.getSettings(current_client_id);

            if (client_settings?.schedule?.default_sms_time?.value) {
                this.default_sms_time = client_settings.schedule.default_sms_time.value
            }
            if (client_settings?.company?.default_timezone?.value) {
                this.default_timezone = client_settings.company.default_timezone.value
            }
            this.render();
        }
    }

    handleSelect(episodes) {
        console.log("handleSelect", episodes);
        this.selected_episodes = episodes;
        this.selected_patients = episodes.map(episode => ({
            id: episode.id,
            first_name: episode.first_name,
            last_name: episode.last_name,
            client_id: episode.client_id,
            episode_id: episode.current_episode_id
        }));
    }





    showConfirmationModal(message) {
        const confirmation_modal = new AppOvalModal();
        confirmation_modal.modalText = message; // Set the modalText property

        confirmation_modal.showModal(); // Make sure this method is properly invoking the Bootstrap modal
        this.refreshTableData();

    }



}

customElements.define("scene-patients", ScenePatients); 