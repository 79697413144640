import { html, render } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { TabulatorFull as Tabulator } from "tabulator-tables";
import { DateTime } from "luxon";
import "tabulator-tables/dist/css/tabulator_materialize.min.css"
//import "tabulator-tables/dist/css/tabulator_bootstrap5.min.css"
import directus from '../../lib/lib-directus';
import AppFilterUtil from './util/app-filter-util';
import './app-filter-field-select-list';
import './app-filter-csv-export';
import { fr } from 'date-fns/locale';

window.luxon = { DateTime };

//const PAGE_SIZE = 20;

/**
 * @typedef {"none","single","multiple"} SelectionType - The type of row selection, if any
 */

class AppFilterTableServer extends HTMLElement {
    constructor() {
        super();
        Tabulator.defaultOptions.responsiveLayoutCollapseStartOpen = false;
        this.toggleState = false;
        this.cachedToken = null;
        this.data_table = null;
        this.checkboxState = {};
        this.selected_ids = new Set();
        this.selected_data = [];
        this._filter_state = {
            group: { type: "_and", groups: [] },
            user_search: ''
        };
        this._collection_config = null;
        this._defaultOptions = {

            alignEmptyValues: "bottom",
            dataSendParams: { "size": "limit" },
            dataLoaderLoading: "<div class='loader' style='display:inline-block;'><img src='images/integration_running.gif' style='width: 35px; height: 35px'></div>",
        }
    }

    get filter_count() {
        return this._result?.meta.filter_count || 0;
    }

    get rows() {
        return this._result?.data;
    }
    get status_filter() {
        return this._status_filter;
        //console.log('table status_filter ggetter', value);
    }

    set status_filter(value) {
        this._status_filter = value;
        //console.log('table status_filter setter', value);
    }
    get schedule_filter() {
        return this._schedule_filter;

    }

    set schedule_filter(value) {
        this._schedule_filter = value;

    }
    set filter_state(value) {
        this._filter_state = value;
        //console.log("filter_state set", this._filter_state);
        this.refreshTableData(); // Ensure this triggers data update in Tabulator
    }
    /** @type {import('./app-filter').FilterState} */
    get filter_state() {
        return this._filter_state;
    }

    set collection_config(value) {
        if (!value)
            return;
        this._collection_config = value;
        this.init();
        //this.render();
    }

    /** @type {import('./app-filter').CollectionConfig} */
    get collection_config() {
        return this._collection_config;
    }

    set selected_columns(value) {
        this._selected_columns = value;
    }

    /** @type {string[]} - A list of paths for columns that are selected for display in the table */
    get selected_columns() {
        return this._selected_columns;
    }

    set table_columns(value) {
        this._table_columns = value;
        //console.log("table_columns set", this._table_columns);
    }

    get table_columns() {
        return this._table_columns;
        //console.log("table_columns get", this._table_columns);
    }

    set options(value) {
        if (typeof value === 'object' && value !== null) {
            // Merge the incoming options with the default options
            this._options = { ...this._defaultOptions, ...value };
        } else {
            console.warn('Invalid options format. Expected an object.');
        }
    }

    get options() {
        return this._options;
        //console.log("options get", this._options);
    }

    /** @type {Object[]|Object} Selected row(s) in the table */
    get selected_data() {
        return this._selected_data;
        //console.log("selected_data get", this._selected_data);
    }

    set selected_data(value) {
        this._selected_data = value;
        //console.log("selected_data set", this._selected_data);
        if (this.selection_type === "multiple") {
            if (value?.length) {
                //this.select_all = value.length === this.rows.length;
                this.selected_ids = new Set(value.map(item => item.id));
            }
            else {
                //this.select_all = false;
                this.selected_ids = new Set();
            }
        }

        this.render();

    }

    set selection_type(value) {
        if (!['none', 'single', 'multiple'].includes(value))
            value = 'none';
        this._selection_type = value;
    }

    /** @type {SelectionType} - Determines whether rows should be selectable, and if they are single or multiselect */
    get selection_type() {
        return this._selection_type;
    }

    /*     set select_all(value) {
            this._select_all = value || false;
        }
    
        get select_all() {
            return this._select_all || false;
        } */

    /**
     * All columns in the collection that are not hidden
     */
    get visible_columns() {
        return AppFilterUtil.getVisibleColumnPaths(null, this.collection_config.fields);
    }

    get unselected_columns() {
        return this.visible_columns?.filter(
            field => !this.selected_columns.includes(field)
        )
    }

    set query(value) {
        this._query = value;

    }
    get query() {
        return this._query;
    }
    connectedCallback() {
        this.render();
        this.initTable().then(() => {
            this.setTableHeight();
        });
    }

    async getAuthToken() {
        if (!this.cachedToken) {
            this.cachedToken = await directus.auth.token; // retrieve token
        }
        return this.cachedToken;
    }

    async initTable() {

        //console.log("initTable selected rows", this.selected_data);

        // make available to the ajaxURLGenerator
        const token = await this.getAuthToken();
        const fieldNames = this.selected_columns.map(name => `fields[]=${name}`).join('&');
        const table_columns = this.table_columns;
        const filter_state = this._filter_state;
        const query = this._query;

        //console.log("Filter State Top of initTable", filter_state);
        //console.log("Query Top of initTable Query", query);

        let columns;
        if (table_columns) {
            columns = this.formatTableColumns(table_columns);
        } else {
            columns = this.formatColumns(this.selected_columns);
        }
        let currentSort = '';
        //console.log("InitTable after...fieldNames", fieldNames);
        //console.log("InitTable after settings table columns after ", this.table_columns);
        //if (this.table_columns) {
        //    columns = this.formatTableColumns(this.table_columns);
        //} else {
        //    columns = this.formatColumns(this.selected_columns);
        //}
        // Make tabulator table option
        let tableOptions = {
            ajaxURLGenerator: function (url, config, params) {

                let queryParams = [];

                // Convert filter object to URL parameter
                if (query.filter) {
                    queryParams.push(`filter=${encodeURIComponent(JSON.stringify(query.filter))}`);
                }

                // Add fields
                if (query.fields && query.fields.length > 0) {
                    queryParams.push(`fields[]=${query.fields.join("&fields[]=")}`);
                }

                // Add sorting parameters from Tabulator
                if (params.sort && params.sort.length > 0) {
                    params.sort.forEach(sorter => {
                        let field = sorter.field;
                        let dir = sorter.dir;

                        // Adjust for columnConfig if necessary
                        const columnConfig = table_columns.find(col => col.field === field);
                        if (columnConfig && columnConfig.sorterParams && columnConfig.sorterParams.field) {
                            field = columnConfig.sorterParams.field;
                        }

                        let sortParam = `sort[]=${dir === 'asc' ? '' : '-'}${field}`;
                        queryParams.push(sortParam);
                    });
                }

                // Pagination parameters
                if (params.page) {
                    queryParams.push(`page=${params.page}`);
                }
                if (params.limit) {
                    queryParams.push(`limit=${params.limit}`);
                }

                // Add other parts of the query as needed
                if (query.meta) {
                    queryParams.push(`meta=${query.meta}`);
                }

                // Combine all query parameters with the base URL
                let finalURL = `${url}?${queryParams.join('&')}`;

                return finalURL;
            },

            ajaxResponse: function (url, params, response) {
                let formattedData;
                if (this.table_columns) {
                    formattedData = this.formatTableRecords(this.table_columns, response.data);
                } else {
                    formattedData = this.formatRecords(response.data);
                }
                let last_page = Math.ceil(response.meta.filter_count / params.limit);
                //console.log("last_page", response.meta.total_count );
                //console.log("response", response);
                //console.log("ajaxResponse params", params);
                return {
                    data: formattedData,
                    last_page,
                };
            }.bind(this), // Bind it or else "this" will be undefined
            ajaxConfig: { headers: { 'Authorization': `Bearer ${token}` } },
            columns: columns, // Columns
        };
        let optionsWithType = this.getOptionsWithTypes(this.options);
        //console.log("optionsWithType",this.options);
        let optionCnt = optionsWithType.length;
        for (let i = 0; i < optionCnt; i++) {
            let option = optionsWithType[i];
            //if (option.key == "data" || option.key == "columns" || option.key == "checkbox")
            //    continue;

            tableOptions[option.key] = option.value;
        }

        this.data_table = new Tabulator("#app-filter-tabulator-table", tableOptions);

        const thisObj = this;
        const thisObj2 = this;

        this.data_table.on("rowSelectionChanged", function (data, rows, selected, deselected) {
            thisObj.dispatchEvent(new CustomEvent('select-row', { bubbles: true, composed: true, detail: data }));
            //console.log("rowSelectionChanged", data);
        });

        this.data_table.on("rowClick", function (e, row) {
            const rowData = row.getData();
            thisObj2.dispatchEvent(new CustomEvent('row-click', { bubbles: true, composed: true, detail: rowData }));
            //console.log("rowClick", rowData);
        });
    }

    refreshTableData() {
        if (this.data_table) {
            //console.log("Refreshing data with filter_state", this._filter_state);
            //this.data_table.replaceData();
            this.initTable();
            //this.data_table.redraw(true);
        }
    }

    //expandCollapseAllRows() { // Expand or collapse all rows (not being used)
    //    Tabulator.defaultOptions.responsiveLayoutCollapseStartOpen = !this.toggleState;
    //    this.toggleState = !this.toggleState;
    //    this.initTable();
    //    //console.log("expandCollapseAllRows", this.toggleState);
    //
    //}


    formatColumns(cols) {
        //console.log("format column search log", this._filter_state.user_search);
        const collectionConfigObj = this.collection_config;
        let columns = [];

        if (this.options?.checkbox)
            columns.push({
                formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false
            });

        if (this.options?.checkboxFreeze)
            columns.push({
                formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false, frozen: true
            });


        cols?.map(column => columns.push({
            title: unsafeHTML(this.collection_config, column).values[0],
            field: column,
            formatter: function (cell) {
                const field = cell.getField();
                const value = cell.getValue();

                const record = (
                    collectionConfigObj,
                    field,
                    value
                );

                return record;
            }
        }));

        return columns;
    }

    formatRecords(rows) {
        let rowCnt = rows?.length;
        let columnCnt = this.selected_columns?.length;

        for (let i = 0; i < rowCnt; i++) {
            for (let k = 0; k < columnCnt; k++) {
                if (!rows[i][this.selected_columns[k]])
                    rows[i][this.selected_columns[k]] = AppFilterUtil.getValue(this.selected_columns[k], rows[i]);
            }
        }

        return rows;
    }

    formatTableColumns(cols) {
        const collectionConfigObj = this.collection_config;
        let columns = [];

        if (this.options?.checkbox) {
            //columns.push({
            //    formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false
            //});
            if (this.options.checkbox === 'freeze') {
                columns.push({
                    formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false, frozen: true
                });
            } else {
                columns.push({
                    formatter: "rowSelection", titleFormatter: "rowSelection", hozAlign: "center", headerSort: false
                });
            }
        }

        cols?.forEach(column => {
            // Ensure column.fields is always an array
            if (!Array.isArray(column.fields)) {
                column.fields = [column.field];
            }
            let colConfig = {
                title: column.name,
                field: column.field,
                formatter: function (cell) {
                    const rowData = cell.getRow().getData();
                    let values = [];

                    if (column.fields.length > 1) {
                        values = column.fields.map(fld => rowData[fld] || '').filter(v => v);
                        return values.join(column.link || ' ');
                    } else {
                        // Fallback to single field or use field property
                        return rowData[column.fields[0]] || '';
                    }
                }
            };
            // Add additional properties from the column object
            for (let key in column) {
                if (column.hasOwnProperty(key) && !['name', 'fields', 'field', 'link'].includes(key)) {
                    colConfig[key] = column[key];
                }
            }

            columns.push(colConfig);
        });

        return columns;
    }


    formatTableRecords(cols, rows) {
        let rowCnt = rows?.length;
        let colCnt = cols?.length;

        for (let i = 0; i < rowCnt; i++) {
            for (let k = 0; k < colCnt; k++) {
                let col = cols[k];

                // Bypass processing for columns with special formatters and no 'field' or 'fields'
                if (col.formatter && !col.field && !col.fields) {
                    continue;
                }

                // Check if the field exists and is not an empty array
                if (!rows[i][col.field] || (Array.isArray(rows[i][col.field]) && rows[i][col.field].length === 0)) {
                    let fields = col.fields || []; // Use an empty array if fields are undefined
                    let fieldCnt = fields.length;
                    let link = col.link || " "; // Default link to space if undefined
                    let value = "";

                    for (let j = 0; j < fieldCnt; j++) {
                        let fieldValue = AppFilterUtil.getValue(fields[j], rows[i]);
                        if (fieldValue) {
                            value += (j !== 0 ? link : "") + fieldValue;
                        }
                    }

                    rows[i][col.field] = value;
                }
            }
        }

        return rows;
    }





    getOptionsWithTypes(obj) {
        const keysWithTypes = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const valueType = typeof obj[key];  // Get the type of the value
                keysWithTypes.push({ key: key, value: obj[key], type: valueType });
            }
        }
        return keysWithTypes;
    }

    setTableHeight() {
        this.data_table.options.height = window.innerHeight - (this.data_table.element.getBoundingClientRect().top + 40);
    }

    disconnectedCallback() {
        /* this.querySelectorAll("tbody tr").forEach(row => {
            row.removeEventListener('mouseover', this.handleRowMouseOver);
            row.removeEventListener('mouseout', this.handleRowMouseOut);
        }); */
    }

    render() {
        //console.log('rendering table', this._filter_state);
        if (!this.template || !this.collection_config) return;
        render(this.template(), this);
    }

    async init() {
        //console.log("init in table-server", this._filter_state);
        this.selected_columns = AppFilterUtil.getVisibleColumnPaths(this.collection_config).slice(0, 4);
    }

    /* handleRowMouseOver(e) {
        const row = e.target.closest('tr');
        if (row) row.classList.add('hovered');
    }
 
    handleRowMouseOut(e) {
        const row = e.target.closest('tr');
        if (row) row.classList.remove('hovered');
    } */

    template() {
        return html`
            <style>
                .app-filter-table-column-header:hover .app-filter-table-remove-column {
                    display: block !important;
                    cursor: pointer;
                }
            </style>
        
            <div id="app-filter-table">
                <div id="drag-clone" style="display: none; position: absolute;" class="app-filter-table-column-header"></div>
                <div id="app-filter-tabulator-table" class="mod-base"></div>    
            </div>
        `;
    }
}

customElements.define('app-filter-table-server', AppFilterTableServer);
export default AppFilterTableServer;