import { html, render } from "lit";

import ModalBase from './util/modal-base';

export default class AppOvalModal extends ModalBase {
    constructor() {
        super();
        this._modalText = '';
        this._patientId = null;
    }

    // Getter for modalText
    get modalText() {
        return this._modalText;
    }

    // Setter for modalText (this will handle the re-rendering)
    set modalText(value) {
        if (typeof value === 'object' && value.patient) {
            this._patientId = value.patient.id;
            this._modalText = value.message || '';
        } else {
            this._modalText = value;
            this._patientId = null;
        }
        this.render();
    }

    connectedCallback() {
        this.template = () => {
            return html`
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body schedule-conf-body xxx" style="display: flex; justify-content: center; align-items: center; padding-top: 2px; padding-bottom: 2px;">
                            <h3 class="schedule-conf-message">${this._modalText}</h3>
                            
                            <div class="schedule-conf-btns">
                                <button id="to-dash" class="btn-light" @click=${() => this.navigate(`/staff/dashboard/overview`)}>
                                    Go to</br>Dashboard
                                </button>
                                ${this._patientId ? html`
                                    <button id="to-patient" class="btn-light" @click=${() => this.navigate(`/staff/patients/${this._patientId}/activity`)}>
                                        Go to</br>Patient Profile
                                    </button>
                                ` : ''}
                                <button id="modal-close" class="btn-light" data-bs-dismiss="modal">
                                    Continue Scheduling
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            `;
        };
        this.render();
    }

    render() {
        if (!this.template) return;
        render(this.template(), this);
    }

    // Custom method for navigation
    navigate(path) {
        window.location.href = path; // Simulate navigation
    }

    /**
     * Click handler for 'Confirm' button.
     * Resolves the ModalBase promise by calling dismiss()
     */
    async handleConfirmClick() {
        this.dismiss({ confirmed: true });
    }
}

customElements.define("app-oval-modal", AppOvalModal);
